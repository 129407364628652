import { useApi } from "../Services/useApi";
import Constants from "../Constants/constants";
import IComplianceContent from "../interfaces/IComplianceContent";
import IComplianceProcess from "../interfaces/IComplianceProcess";
import { ComplianceTypes, DocumentType, EmailAddressSource, ProcessSource, ProcessStatus } from "../Constants/enums";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import IDocumentDefinition from "../interfaces/IDocumentDefinition";
import InfoTypes from "../interfaces/InfoTypes";
import LocalStorageService from "./localStorageService";

const InitialComplianceTypeContent = {
    id: "",
    intro: {
      content: "",
      detail: "",
      title: ""
    },
    steps: [{
      content: "",
      detail: "",
      title: ""
    }],
    outro: {
      content: "",
      detail: "",
      title: ""
    }
}


export function GetComplianceTypeUIContent(complianceType: string) {
  const url = Constants.API_URLS.COMPLIANCE + complianceType.toLowerCase() + Constants.API_URLS.GET_COMPLIANCE_CONTENT;
  
  const {error, data } = useApi<IComplianceContent>(url);
  let uiContent: IComplianceContent = InitialComplianceTypeContent;
  if (!error) {
    if(data !== null && data !== undefined) {
      uiContent = data
    }
  }
  return uiContent;
}

export function GetNationalitiesData(): InfoTypes.Nationality[] {

  const { error, data } = useApi<InfoTypes.Nationality[]>(Constants.API_URLS.GET_NATIONALITIES);
  let nationalityData: InfoTypes.Nationality[] = [];

  if (!error) {
    if(data !== null && data !== undefined && data.length > 0) {
        
      nationalityData = _.map(data!, (e: InfoTypes.Nationality) => {
          return {title: e.value, id: e.id, value: e.id };
      });
      LocalStorageService.setData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA, nationalityData);
    }
  }
  return nationalityData;
}

export function GetNationalityDescription(nationalityIso: string): string {
  let nationalitiesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.NATIONALITIES_DATA);
  if (!nationalitiesData) {
    nationalitiesData = GetNationalitiesData();
  }
  const nationality = _.find(nationalitiesData, (ntnl: InfoTypes.Nationality) => { return (ntnl.id === nationalityIso) });
  if (nationality) {
    return nationality!.title!;
  } else {
    return "";
  }
}

export function GetCountriesData(): InfoTypes.Country[] {
  const { error, data } = useApi<InfoTypes.Country[]>(Constants.API_URLS.GET_COUNTRIES);
  let countryData: InfoTypes.Country[] = [];

  if (!error) {
    if(data !== null && data !== undefined && data.length > 0) {
        
      countryData = _.map(data!, (e: InfoTypes.Country) => {
          return {title: e.value, id: e.id, value: e.id };
      });
      LocalStorageService.setData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA, countryData);
    }
  }
  return countryData;
}

export function GetCountryDescription(cntryIso: string): string {
  let countriesData = LocalStorageService.getData(Constants.LOCAL_STORE_KEY.COUNTRIES_DATA);
  if (!countriesData) countriesData = GetCountriesData();
  
  const country = _.find(countriesData, (cntry: InfoTypes.Country) => { return (cntry.id === cntryIso) });
  if (country) return country.title;
  else return "";
}

export function CheckHasClientActions(processData: IComplianceProcess) {
  const { user } = useAuth0();
    return (processData && processData.status === ProcessStatus.WaitingForClient &&
      user && processData.requestedBy && processData.requestedBy.requester?.toLowerCase() === user.email?.toLowerCase())
}

export function CheckHasCounterSignerActions(processData: IComplianceProcess, userRoles: string[]) {
  return processData && processData.complianceType === ComplianceTypes.DBS && 
    processData.status === ProcessStatus.WaitingForCounterSigning && 
    userRoles.includes("countersigner");
}

export function RenderRTWExternalLinkToVerifyIdentity(checkData: IComplianceProcess) {
  if (checkData && checkData.complianceType === ComplianceTypes.IdentityAndRWAudit && checkData.processData && checkData.processData.documents) {
    const docType = _.filter(checkData.processData.documents, (doc: IDocumentDefinition) => { return (doc.type === DocumentType[DocumentType.SettledStatusShareCode]) });
    return (docType && docType.length > 0);
  }
  return false;
}

export function RenderRightToWorkCheckStatus(complianceProcessData: IComplianceProcess) {
  return complianceProcessData && 
          complianceProcessData!.complianceType === ComplianceTypes.IdentityAndRWAudit && 
          complianceProcessData!.status === ProcessStatus.Complete
}

export function RenderIdentityCheckStatus(complianceProcessData: IComplianceProcess) {
  return complianceProcessData && 
          complianceProcessData!.complianceType === ComplianceTypes.IdentityCheck && 
          complianceProcessData!.status === ProcessStatus.Complete
}

export function CanCancelCheck(complianceProcessData: IComplianceProcess, userRoles: string[]) {
  const { user } = useAuth0();
  return complianceProcessData && 
          ((user && complianceProcessData.requestedBy && 
            complianceProcessData.requestedBy.requester?.toLowerCase() === user.email?.toLowerCase()) ||  
            userRoles.includes("countersigner")) &&
          complianceProcessData!.status !== ProcessStatus.Complete && 
          complianceProcessData!.status !== ProcessStatus.Cancelled &&
          complianceProcessData!.status !== ProcessStatus.SubmittedToThirdParty
}

export function IsAdminEmail() {
  const { user } = useAuth0();
  return user!.email && 
          user!.email.endsWith(EmailAddressSource.TalentClouds);
}

export function CanResetOrDisplayYotiSession(complianceProcessData: IComplianceProcess) {
  const isTalentCloudsEmail = IsAdminEmail();
  
  return complianceProcessData && 
    complianceProcessData!.hasOwnProperty('digitalIdentity') && 
    isTalentCloudsEmail
}

export function CheckWaitingForWorkerStatus(complianceProcessData: IComplianceProcess) {
  return complianceProcessData && 
          complianceProcessData!.status === ProcessStatus.WaitingForWorker
}