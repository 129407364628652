import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Suspense } from "react";

import Auth0ProviderWithHistory from "./Auth/Auth0ProviderWithHistory";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./index.scss";

import { makeServer } from "./mirage/index";
import Routes from "./Routes/routes";

const environment = process.env.NODE_ENV;

if (environment !== "production") {
  makeServer({ environment });
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <I18nextProvider i18n={i18n} >
        <BrowserRouter basename="/" forceRefresh={true}>
          <Auth0ProviderWithHistory>
            <Routes />
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
