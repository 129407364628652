import { useTranslation } from "react-i18next";
import { Action } from 'react-tiny-fab';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import BookIcon from '@material-ui/icons/Book';
import SyncIcon from '@material-ui/icons/Sync';
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import { CanCancelCheck, CanResetOrDisplayYotiSession, CheckWaitingForWorkerStatus } from "../../Utils/referenceDataService";

interface ICommonActionsProps {
    complianceProcessData: IComplianceProcess;
    userRoles: string[];
    handleCancelCheck: () => void;
    handleViewAuditTrail: () => void;
    handleResetYotiSession: () => void;
}

export default function CommonActions(props: ICommonActionsProps) {
    const { t } = useTranslation();

    const canCancelCheck: boolean = CanCancelCheck(props.complianceProcessData, props.userRoles) || false;
    const canResetYotiSession: boolean = CanResetOrDisplayYotiSession(props.complianceProcessData) || false;
    const checkWaitingForWorkerStatus: boolean = CheckWaitingForWorkerStatus(props.complianceProcessData) || false;

    return (
      <>
        {(canCancelCheck) &&
          <Action
            text={t("check_summary.cancel_check")}
            onClick={props.handleCancelCheck}>
            <div className="actn-btn">
              <div className="span-txt">
                <span>{t("check_summary.cancel_check")}</span>
              </div>
              <div className="svg-el">
                <CancelPresentationIcon />
              </div>
            </div>
          </Action>
        }

        <Action
          text={t("check_summary.view_audit_log")}
          onClick={props.handleViewAuditTrail}>
          <div className="actn-btn">
            <div className="span-txt">
              <span>{t("check_summary.view_audit_log")}</span>
            </div>
            <div className="svg-el">
              <BookIcon />
            </div>
          </div>
        </Action>

        {(canResetYotiSession && checkWaitingForWorkerStatus) &&
          <Action
            text={t("check_summary.reset_yoti")}
            onClick={props.handleResetYotiSession}>
            <div className="actn-btn">
              <div className="span-txt">
                <span>{t("check_summary.reset_yoti")}</span>
              </div>
              <div className="svg-el">
                <SyncIcon />
              </div>
            </div>
          </Action>
        }
      </>
    )
}