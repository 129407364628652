import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./Header";
import CustomDrawer  from "./Menu/CustomDrawer";
import Footer from "./Footer";

import { DrawerContextProvider } from "../Contexts/drawer-context";
import { Container, CssBaseline } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
  },
  content: {
    flexGrow: 2,
    display: "flex",
    marginTop: "80px", 
  },
  container: {
    flexGrow: 2,
    minWidth: "100%",
    display:"flex",
    minHeight: `calc(100vh - 140px)`,
    marginTop: "90px",
    backgroundColor: "#FAFAFA",
    borderRadius: "30px",
    border: "1px transparent",
    paddingTop: "50px",
  },
  leftContainer: {
    float: "left", 
    marginTop: "80px", 
  },
  appBarSpacer: theme.mixins.toolbar,
}));

type Props = {
  children: NonNullable<ReactNode>;
};

const Layout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <DrawerContextProvider>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <CustomDrawer />
        <main className="main-Screen">
          <Container maxWidth="lg" className={classes.container}>
            {children}
          </Container>
            
        </main>
      </div>
      <Footer/>
    </DrawerContextProvider>
  );
};

export default Layout;
