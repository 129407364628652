var Constants = {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    CLAIMS_URL: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    APP_ROUTES: {
        DEFAULT: "/",
        DASHBORAD: "/dashboard",
        CHECK_SUMMARY_VIEW: "/check-summary",
        CHECK_DETAILS: "/check-details"
    },
    VALID_PREVIEW_FILE_TYPES: ["png", "jpg", "jpeg", "pdf"],
    API_URLS: {
        COMPLIANCE: "/compliance/",
        DOCUMENTS: "/documents/",
        RTW: "rtw/",
        ID_CHECK: "id-check/",
        GET_WORKER_ROLE_COMPLIANCE: "/compliance/",
        GET_COMPLIANCE_CONTENT: "/content",
        DBS_SUFFIX: "/compliance/dbs/",
        PUT_COUNTERSIGINING_DETAILS: "/countersign",
        POST_IDCHECK_CONFIRM: "/idcheck",
        POST_CANCEL_PROCESS: "/cancel",
        POST_SUBMIT_CHANGE_REQUEST: "/change-request/submit",
        POST_SUBMIT_COUNTERSIGN: "/countersign",
        POST_CHECK_COMPLETE: "/complete",
        GET_COUNTRIES: "/reference/countries",
        GET_NATIONALITIES: "/reference/nationalities",
        GET_DOCUMENT_TYPE_FIELDS: "/fields",
        REMOVE_DIGITAL_IDENTITY: "/compliance/api/remove-digital-identity/",
    },
    LOCAL_STORE_KEY: {
        COUNTRIES_DATA: "COUNTRIES_DATA",
        NATIONALITIES_DATA: "NATIONALITIES_DATA",
    },
    COMPLIANCE_STEPS_EDITORS: {
        PERSONAL_INFO: "PersonalInfo",
        ADDRESS_INFO: "AddressInfo",
        IDENTITY_VERFIICAITON: "IdentityVerification",
        INTRO: "Intro",
        OUTRO: "Outro",
        CONVICTIONS: "Convictions",
        WORK_ABROAD: "WorkAbroad",
    },
    API_CALLS: {
        REQUEST_DBS_CHANGE: "ChangeDbsDetails",
    },
    DBS_TRACKING_SERVICE_URL: "https://secure.crbonline.gov.uk/enquiry/enquirySearchAction.do"
}

export default Constants;