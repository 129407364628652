import Constants from "../Constants/constants";
import CookieStorageService from "../Utils/cookieStorageService";

async function secureFetch(endpoint, { method, body, ...customConfig } = {}) {
  const token = CookieStorageService.getCookie(Constants.ACCESS_TOKEN);
  const headers = { "Content-Type": "application/json" };
  const API_GATEWAY = process.env.REACT_APP_APIGATEWAY;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const config = {
    method: method !== "" ? method : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(`${API_GATEWAY}${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        window.location.assign("/logout");
        return;
      }
      if (response.ok) {
        if (response.status === 202) {
          return response;
        } else return await response?.json();
      } else {
        if (response.status === 400) {
          return await response?.json();
        } else {
          const errorMessage = await response.text();
          if (config.method === "PATCH" || config.method === "PUT") {
            return response;
          } else return Promise.reject(new Error(errorMessage));
        }
      }
    });
}

export default secureFetch;
