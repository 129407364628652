import "./index.scss";
import { useTranslation } from "react-i18next";
import CheckSummaryView from "../CheckSummaryView";
import CheckHeader from "./CheckHeader";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import Constants from "../../Constants/constants";
import { GenericLoader } from "../../Components/GenericLoader";
import ICheckHeader from "../../interfaces/ICheckHeader";
import { useParams } from "react-router-dom";
import { ConfirmActions } from "../../Constants/enums";
import { useEffect, useState } from "react";
import RequestChangeModal from "./RequestChangeModal";
import RequestChangeDbsDetailsModal from "./RequestChangeDbsDetailsModal";
import secureFetch from "../../Services/secureFetch";
import GenericAlert from "../../Components/GenericAlert";
import CookieStorageService from "../../Utils/cookieStorageService";
import { useAuth0 } from "@auth0/auth0-react";
import CheckActions from "../CheckActions/CheckActions";
import CheckAuditLogModal from "./CheckAuditLog";
import {
  GetCountriesData,
  GetNationalitiesData,
} from "../../Utils/referenceDataService";

type CheckDetailsParams = {
  id: string;
  source: string;
};

export default function CheckDetails() {
  const { t } = useTranslation();
  const { id, source } = useParams<CheckDetailsParams>();

  const [isOpenCommentModal, setIsOpenCommentModal] = useState(false);
  const [isOpenChangeDbsModal, setIsOpenChangeDbsModal] = useState(false);
  const [isOpenAuditLogModal, setIsOpenAuditLogModal] = useState(false);

  const [callRefresh, setCallRefresh] = useState(false);
  const [showOutcome, setShowOutcome] = useState(false);
  const [onCloseOutcomeSuccess, setOnCloseOutcomeSuccess] = useState(false);
  const [acceptCheckRequest, setAcceptCheckRequest] = useState(false);
  const [cancelCheckRequest, setCancelCheckRequest] = useState(false);
  const [actionMessage, setActionMessage] = useState("");
  const [acceptCounterSigner, setAcceptCounterSigner] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [complianceProcessData, setComplianceProcessData] =
    useState<IComplianceProcess>();
  const [loader, setLoader] = useState(false);
  const [confirmAction, setConfirmAction] = useState<ConfirmActions>(
    ConfirmActions.None
  );
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [completeCheckRequest, setCompleteCheckRequest] = useState(false);
  const [hasRTW, setHasRTW] = useState(false);
  const [doesntHaveRTW, setDoesntHaveRTW] = useState(false);
  const [confirmIdentity, setConfirmIdentity] = useState(false);
  const [rejectIdentity, setRejectIdentity] = useState(false);
  const [resetYoti, setResetYoti] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const accessToken = await getAccessTokenSilently();
      CookieStorageService.setCookie(Constants.ACCESS_TOKEN, accessToken);

      const tokenClaims = await getIdTokenClaims();
      setUserRoles(tokenClaims[Constants.CLAIMS_URL]);

      RefreshComplianceProcessData();
    })();
  }, []);

  useEffect(() => {
    async function SendRefreshRequest() {
      if (callRefresh) {
        await RefreshComplianceProcessData();
      }
    }
    SendRefreshRequest();
  }, [callRefresh]);

  const RefreshComplianceProcessData = () => {
    setLoader(true);
    secureFetch(
      `${Constants.API_URLS.GET_WORKER_ROLE_COMPLIANCE}${id}/${source}`,
      {
        method: "GET",
        body: "",
      }
    )
      .then((res) => {
        setComplianceProcessData(res!);
        setLoader(false);
      })
      .catch(() => {
        setCallRefresh(false);
        setLoader(false);
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
    resetState();
  };

  GetCountriesData();
  GetNationalitiesData();

  useEffect(() => {
    async function SendAcceptCheckRequest() {
      acceptCheckRequest && (await ActionCheckRequest(true));
    }
    SendAcceptCheckRequest();
  }, [acceptCheckRequest]);

  useEffect(() => {
    async function SendCancelCheckRequest() {
      cancelCheckRequest && (await ActionCheckRequest(false));
    }
    SendCancelCheckRequest();
  }, [cancelCheckRequest]);

  const ActionCheckRequest = (isAccept: boolean) => {
    let url = "";
    if (isAccept) {
      url =
        Constants.API_URLS.DBS_SUFFIX +
        id +
        Constants.API_URLS.POST_IDCHECK_CONFIRM;
    } else {
      url =
        Constants.API_URLS.COMPLIANCE +
        id +
        Constants.API_URLS.POST_CANCEL_PROCESS;
    }
    secureFetch(url, {
      method: "POST",
      body: "",
    })
      .then((res) => {
        if (res.ok) {
          setOnCloseOutcomeSuccess(true);
          setActionMessage(
            isAccept
              ? t("request_comments.check_accepted")
              : t("request_comments.check_rejected")
          );
        } else {
          setOnCloseOutcomeSuccess(false);
          setActionMessage(t("errors.error_msg"));
          setShowOutcome(true);
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
  };

  useEffect(() => {
    async function SendAcceptCountersignRequest() {
      acceptCounterSigner && (await AcceptCountersignRequest());
    }
    SendAcceptCountersignRequest();
  }, [acceptCounterSigner]);

  const AcceptCountersignRequest = () => {
    let dbsDetails = complianceProcessData!.dbsDetails!.counterSignDetails
      ? complianceProcessData!.dbsDetails!.counterSignDetails
      : complianceProcessData!.dbsDetails!.requestInfo;

    secureFetch(
      Constants.API_URLS.DBS_SUFFIX +
        id +
        Constants.API_URLS.POST_SUBMIT_COUNTERSIGN,
      {
        method: "POST",
        body: dbsDetails,
      }
    )
      .then((res) => {
        if (res.ok) {
          setOnCloseOutcomeSuccess(true);
          setActionMessage(t("check_details.check_countersigned"));
        } else {
          setOnCloseOutcomeSuccess(false);
          setActionMessage(t("errors.error_msg"));
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
  };

  useEffect(() => {
    async function SendCompleteCheckRequest() {
      completeCheckRequest && (await CompleteCheckRequest(false));
    }
    SendCompleteCheckRequest();
  }, [completeCheckRequest]);

  const CompleteCheckRequest = (isAccept: boolean) => {
    secureFetch(
      Constants.API_URLS.COMPLIANCE +
        id +
        Constants.API_URLS.POST_CHECK_COMPLETE,
      {
        method: "POST",
        body: "",
      }
    )
      .then((res) => {
        if (res.ok) {
          setOnCloseOutcomeSuccess(true);
          setActionMessage(t("check_details.check_completed"));
        } else {
          setOnCloseOutcomeSuccess(false);
          setActionMessage(t("errors.error_msg"));
          setShowOutcome(true);
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
  };

  useEffect(() => {
    async function SendRTWCheckRequest() {
      hasRTW && (await MarkRTWCheckRequest(true));
    }
    SendRTWCheckRequest();
  }, [hasRTW]);

  useEffect(() => {
    async function SendRTWCheckRequest() {
      doesntHaveRTW && (await MarkRTWCheckRequest(false));
    }
    SendRTWCheckRequest();
  }, [doesntHaveRTW]);

  const MarkRTWCheckRequest = (hasRightToWork: boolean) => {
    var otherInfo = {
      hasRightToWork: hasRightToWork,
    };
    secureFetch(
      Constants.API_URLS.COMPLIANCE +
        Constants.API_URLS.RTW +
        id +
        Constants.API_URLS.POST_CHECK_COMPLETE,
      {
        method: "POST",
        body: otherInfo,
      }
    )
      .then((res) => {
        if (res.ok) {
          setOnCloseOutcomeSuccess(true);
          setActionMessage(t("check_details.rtw_status_updated"));
        } else {
          setOnCloseOutcomeSuccess(false);
          setActionMessage(t("errors.error_msg"));
          setShowOutcome(true);
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
  };

  useEffect(() => {
    async function SendIdentityCheckRequest() {
      confirmIdentity && (await MarkIdentityCheckRequest(true));
    }
    SendIdentityCheckRequest();
  }, [confirmIdentity]);

  useEffect(() => {
    async function SendIdentityCheckRequest() {
      rejectIdentity && (await MarkIdentityCheckRequest(false));
    }
    SendIdentityCheckRequest();
  }, [rejectIdentity]);

  const MarkIdentityCheckRequest = (confirm: boolean) => {
    var otherInfo = {
      hasIdentityChecked: confirm,
    };
    secureFetch(
      Constants.API_URLS.COMPLIANCE +
        Constants.API_URLS.ID_CHECK +
        id +
        Constants.API_URLS.POST_CHECK_COMPLETE,
      {
        method: "POST",
        body: otherInfo,
      }
    )
      .then((res) => {
        if (res.ok) {
          setOnCloseOutcomeSuccess(true);
          setActionMessage(t("check_details.id_check_status_updated"));
        } else {
          setOnCloseOutcomeSuccess(false);
          setActionMessage(t("errors.error_msg"));
          setShowOutcome(true);
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
  };

  useEffect(() => {
    async function SendRemoveDigitalIDInfoData() {
      resetYoti && (await RemoveDigitalIDInfoData());
    }
    SendRemoveDigitalIDInfoData();
  }, [resetYoti]);

  const RemoveDigitalIDInfoData = () => {
    secureFetch(Constants.API_URLS.REMOVE_DIGITAL_IDENTITY + id, {
      method: "GET",
      body: "",
    })
      .then((res) => {
        if (res.success) {
          setOnCloseOutcomeSuccess(true);
          setActionMessage(t("check_details.reset_yoti_completed"));
        } else {
          setOnCloseOutcomeSuccess(false);
          setActionMessage(t("errors.error_msg"));
          setShowOutcome(true);
        }
        setShowOutcome(true);
      })
      .catch(() => {
        setOnCloseOutcomeSuccess(false);
        setActionMessage(t("errors.error_msg"));
        setShowOutcome(true);
      });
  };

  const toggleCommentsModal = () => {
    setIsOpenCommentModal(!isOpenCommentModal);
  };

  const toggleChangeDbsModal = () => {
    setIsOpenChangeDbsModal(!isOpenChangeDbsModal);
  };

  const toggleAuditLogModal = () => {
    setIsOpenAuditLogModal(!isOpenAuditLogModal);
  };

  const GetHeader = (
    complianceData: IComplianceProcess
  ): ICheckHeader | null => {
    const { requestedBy } = complianceData;
    if (complianceData.engagementDetails) {
      return {
        worker: complianceData.engagementDetails!.assignedToName!,
        organisation: complianceData.engagementDetails!.client!,
        location: complianceData.engagementDetails!.location!,
        role: complianceData.engagementDetails!.role!,
        dbsRequest: complianceData!,
        email: complianceData.assignedTo!,
      };
    } else return null;
  };

  const handleAcceptByClient = () => {
    setConfirmAction(ConfirmActions.SubmitToCountersign);
    setShowConfirm(true);
  };

  const handleAcceptByCounterSigner = () => {
    setConfirmAction(ConfirmActions.SubmitToDBS);
    setShowConfirm(true);
  };

  const handleCancelCheck = () => {
    setConfirmAction(ConfirmActions.Cancel);
    setShowConfirm(true);
  };

  const handleRequestChangeWithComments = () => {
    toggleCommentsModal();
  };

  const handleOnCloseModals = (isSuccess: boolean, operation?: string) => {
    setOnCloseOutcomeSuccess(isSuccess);
    setShowOutcome(true);
    if (isSuccess) {
      if (operation && operation === Constants.API_CALLS.REQUEST_DBS_CHANGE) {
        setActionMessage(t("check_details.request_details.check_data_changed"));
      } else {
        setActionMessage(t("request_comments.success_client"));
      }
    } else {
      setActionMessage(t("errors.error_msg"));
    }
  };

  const handleRequestChange = () => {
    setIsOpenChangeDbsModal(true);
  };

  const resetState = () => {
    setAcceptCheckRequest(false);
    setCancelCheckRequest(false);
    setAcceptCounterSigner(false);
    setLoader(false);
    setConfirmAction(ConfirmActions.None);
    setCallRefresh(false);
  };

  const handleViewAuditTrail = () => {
    toggleAuditLogModal();
  };

  const handleCompleteCheck = () => {
    setConfirmAction(ConfirmActions.Complete);
    setShowConfirm(true);
  };

  const handleRightToWorkFlagOnCheck = (hasRightToWork: boolean) => {
    setConfirmAction(
      hasRightToWork
        ? ConfirmActions.HasRightToWork
        : ConfirmActions.DoesntHaveRightToWork
    );
    setShowConfirm(true);
  };

  const handleIdentityFlagOnCheck = (confirm: boolean) => {
    setConfirmAction(
      confirm ? ConfirmActions.ConfirmIdentity : ConfirmActions.RejectIdentity
    );
    setShowConfirm(true);
  };

  const handleResetYotiSession = () => {
    setConfirmAction(ConfirmActions.ResetYotiSession);
    setShowConfirm(true);
  };

  const loadMessage = (confirmAction: ConfirmActions) => {
    switch (confirmAction) {
      case ConfirmActions.Cancel:
        return (
          <>
            {t("check_details.confirm_cancel_message")}
            <br />
            {t("check_details.confirm_cancel_message_notice")}
          </>
        );
      case ConfirmActions.SubmitToCountersign:
        return t("check_details.confirm_submit_countersign_message");
      case ConfirmActions.SubmitToDBS:
        return t("check_details.confirm_submit_dbs_message");
      case ConfirmActions.Complete:
        return t("check_details.confirm_complete_check");
      case ConfirmActions.HasRightToWork:
      case ConfirmActions.DoesntHaveRightToWork:
        return t("check_details.confirm_rtw_status_update");
      case ConfirmActions.ConfirmIdentity:
      case ConfirmActions.RejectIdentity:
        return t("check_details.confirm_identity_status_update");
      case ConfirmActions.ResetYotiSession:
        return t("check_details.confirm_reset_yoti_message");
      default:
        return "";
    }
  };

  return loader ? (
    <GenericLoader />
  ) : (
    <div id="check-details-wrapper" className="check-details-wrapper">
      <CheckActions
        complianceProcessData={complianceProcessData!}
        userRoles={userRoles}
        handleAcceptByClient={handleAcceptByClient}
        handleAcceptByCounterSigner={handleAcceptByCounterSigner}
        handleCancelCheck={handleCancelCheck}
        handleRequestChange={handleRequestChange}
        handleRequestChangeWithComments={handleRequestChangeWithComments}
        handleViewAuditTrail={handleViewAuditTrail}
        handleCompleteCheck={handleCompleteCheck}
        handleRightToWorkFlagOnCheck={handleRightToWorkFlagOnCheck}
        handleIdentityFlagOnCheck={handleIdentityFlagOnCheck}
        handleResetYotiSession={handleResetYotiSession}
      />
      <div className="check-personal-details-container">
        {complianceProcessData?.status === "WaitingForClient" && (
          <div className="help box">
            Please confirm the check details you requested are correct and that
            the employee has input their correct details. Once you're happy
            simply click the + symbol in the top right corner and select the
            required action.
          </div>
        )}
        <div className="header box">
          <div className="title">
            {complianceProcessData && (
              <CheckHeader
                checkHeader={GetHeader(complianceProcessData!)}
                complianceProcessData={complianceProcessData!}
              />
            )}
          </div>
        </div>
        <div className="details-container">
          {complianceProcessData && (
            <div className="container-content">
              <CheckSummaryView
                complianceProcessData={complianceProcessData!}
              />
            </div>
          )}
        </div>
      </div>

      {complianceProcessData && (
        <RequestChangeModal
          isOpen={isOpenCommentModal}
          toggleModal={toggleCommentsModal}
          complianceProcessId={complianceProcessData!.id}
          onCloseModal={handleOnCloseModals}
        />
      )}

      {complianceProcessData && complianceProcessData.dbsDetails && (
        <RequestChangeDbsDetailsModal
          toggleModal={toggleChangeDbsModal}
          dbsDetails={
            complianceProcessData!.dbsDetails!.counterSignDetails
              ? complianceProcessData!.dbsDetails!.counterSignDetails
              : complianceProcessData!.dbsDetails!.requestInfo!
          }
          checkId={complianceProcessData!.id}
          isOpen={isOpenChangeDbsModal}
          onCloseModal={handleOnCloseModals}
        />
      )}

      {showOutcome ? (
        <GenericAlert
          showButtons={true}
          showSecondaryBtn={false}
          error={!onCloseOutcomeSuccess}
          showSuccessIcon={onCloseOutcomeSuccess}
          primaryButtonText={t("common.ok")}
          onCloseModal={() => {
            setCallRefresh(true);
            setShowOutcome(false);
          }}
          onPrimaryButtonClicked={() => {
            setShowOutcome(false);
            setCallRefresh(true);
          }}
          open={showOutcome}
          modalHeight={"250px"}
          modalWidth="620px"
          modalMargin="200px auto"
        >
          <div className="modal-input">{actionMessage}</div>
        </GenericAlert>
      ) : (
        ""
      )}

      <GenericAlert
        showButtons={true}
        showSecondaryBtn={true}
        error={false}
        showAlertIcon={true}
        showSuccessIcon={false}
        primaryButtonText={t("common.proceed")}
        secondaryButtonText={t("common.cancel")}
        onCloseModal={() => {
          setShowConfirm(false);
        }}
        onPrimaryButtonClicked={() => {
          switch (confirmAction) {
            case ConfirmActions.Cancel:
              setCancelCheckRequest(true);
              break;
            case ConfirmActions.SubmitToCountersign:
              setAcceptCheckRequest(true);
              break;
            case ConfirmActions.SubmitToDBS:
              setAcceptCounterSigner(true);
              break;
            case ConfirmActions.Complete:
              setCompleteCheckRequest(true);
              break;
            case ConfirmActions.HasRightToWork:
              setHasRTW(true);
              break;
            case ConfirmActions.DoesntHaveRightToWork:
              setDoesntHaveRTW(true);
              break;
            case ConfirmActions.ConfirmIdentity:
              setConfirmIdentity(true);
              break;
            case ConfirmActions.RejectIdentity:
              setRejectIdentity(true);
              break;
            case ConfirmActions.ResetYotiSession:
              setResetYoti(true);
              break;
            default:
              setConfirmAction(ConfirmActions.None);
          }
          setShowConfirm(false);
        }}
        open={showConfirm}
        modalHeight={"250px"}
        modalWidth="620px"
        modalMargin="200px auto"
      >
        <div className="modal-input">{loadMessage(confirmAction)}</div>
      </GenericAlert>

      {complianceProcessData && (
        <CheckAuditLogModal
          isOpen={isOpenAuditLogModal}
          toggleModal={toggleAuditLogModal}
          complianceProcessData={complianceProcessData!}
        />
      )}
    </div>
  );
}
