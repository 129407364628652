import * as React from "react";
import "./index.scss";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "../../Assets/model-close.svg";

export interface ModalProps {
  heading?: any;
  onCloseModal: () => void;
  open: boolean;
  children: React.ReactElement;
  className?: string;
  modalWidth?: string;
  modalHeight?: string;
  modalMargin?: string;
  modalPadding?: string;
  modalTop?: string;
  modalBottom?: string;
  modalRight?: string;
  closeOnBackDrop?: boolean;
  hideModalClose?: boolean;
}

const GenericModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal open={props.open} className="modal-container" onClose={(e, reason) => {
      if(reason === "backdropClick" && props.closeOnBackDrop)
        props.onCloseModal();
    }}>
      <div
        className="modal-inner"
        style={{
          height: props.modalHeight,
          width: props.modalWidth,
          margin: props.modalMargin,
          top: props.modalTop,
          bottom: props.modalBottom,
          right: props.modalRight,
          padding: props.modalPadding
        }}
      >
        <div className="section-header">
          <div className="section-name" aria-label={props.heading}>
            {props.heading}
            {!(props.hideModalClose) ?
            <img
              src={CloseIcon}
              alt="Close"
              className="close-icon"
              onClick={props.onCloseModal}
            /> : ""}
          </div>
        </div>
        <div className={`modal-content  ${props.className}`}>
          {props.children}
        </div>
      </div>
    </Modal>
  );
};

export default GenericModal;
