
import moment from "moment";
import GenericDataShow from "../../../Components/GenericDataShow";
import InfoTypes from "../../../interfaces/InfoTypes";
import "./index.scss";
import { useTranslation } from "react-i18next"

interface PreviousNameProps {
    prevNameData: InfoTypes.PreviousNames;
    pNameIdx: number;
}

export default function PreviousName (props: PreviousNameProps) {
    const { t } = useTranslation();

    return (
        <div id={`persona-info-prevName-div-${props.pNameIdx}`}  className="personal-info-body-container-details empty-row">
            <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                    label={t("profile_details.prev_forename")}
                    value={props.prevNameData.forename}
                    />
                </div>
                <div className="oneHalfWidth">
                    <GenericDataShow
                    label={t("profile_details.prev_surname")}
                    value={props.prevNameData.surname} 
                    />
                </div>
            </div>
            <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={t("common.date_from_generic")}
                        key={`prevName.from-${props.pNameIdx}`}
                        value={props.prevNameData.from ? moment(props.prevNameData.from).format("DD/MM/YYYY"): ""}
                    />
                </div>
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={t("common.date_to_generic")}
                        key={`prevName.to-${props.pNameIdx}`}
                        value={props.prevNameData.to ? moment(props.prevNameData.to).format("DD/MM/YYYY") : ""}
                    />
                </div>
            </div>
        </div>
    )
}