import "./index.scss";
import PersonalInformation from "../Editors/PersonalInfo";
import AddressHistory from "../Editors/AddressHistory";
import Constants from "../../Constants/constants";
import { useState } from "react";
import InfoTypes from "../../interfaces/InfoTypes";
import VerifyIdentity from "../Editors/VerifyIdentity";
import IComplianceContent from "../../interfaces/IComplianceContent";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { IconButton, makeStyles } from "@material-ui/core";
import Convictions from "../Editors/Convictions";
import WorkedAbroad from "../Editors/WorkedAbroad";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import { GetComplianceTypeUIContent } from "../../Utils/referenceDataService";

const useStyles = makeStyles(() => ({
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    position: "sticky",
    textAlign: "center"
  },
}));

interface CheckSummaryViewProps {
  complianceProcessData?: IComplianceProcess;
  enableCountersign?: boolean
}

export default function CheckSummaryView(props: CheckSummaryViewProps) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const dbsUIContent: IComplianceContent = GetComplianceTypeUIContent(props.complianceProcessData?.complianceType!);
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNextStep = ( updatedProcessData?: IComplianceProcess, editor?: string ) => {
    if (updatedProcessData) {
      if (props.complianceProcessData) {

        if (!props.complianceProcessData.processData) {
          props.complianceProcessData.processData = {};
        }
        switch (editor!.toLowerCase().trim()) {
          case Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO.toLowerCase():
            props.complianceProcessData.processData.personal = updatedProcessData.processData.personal;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO.toLowerCase():
            props.complianceProcessData.processData.addresses = updatedProcessData.processData.addresses;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS.toLowerCase():
            props.complianceProcessData.processData.convictions = updatedProcessData.processData.convictions;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD.toLowerCase():
            props.complianceProcessData.processData.other = updatedProcessData.processData.other;
            break;
          case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON.toLowerCase():
            props.complianceProcessData.processData.documents = updatedProcessData.processData.documents;
            break;
          default:
            break;
        }
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const loadStepContainer = (editor: string) => {
    switch (editor) {
      case Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO:
        return <PersonalInformation uiContent={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.PERSONAL_INFO })]} complianceProcessData={props.complianceProcessData!} />
      case Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO:
        return <AddressHistory uiContent={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.ADDRESS_INFO })]} complianceProcessData={props.complianceProcessData!} />
      case Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON:
        return <VerifyIdentity uiContent={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.IDENTITY_VERFIICAITON })]} complianceProcessData={props.complianceProcessData!} />
      case Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS:
        return <Convictions content={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.CONVICTIONS })]} complianceProcessData={props.complianceProcessData!} />
      case Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD:
        return <WorkedAbroad content={dbsUIContent.steps[dbsUIContent.steps.findIndex((s: InfoTypes.ContentDefinition) => { return s.editor === Constants.COMPLIANCE_STEPS_EDITORS.WORK_ABROAD })]} complianceProcessData={props.complianceProcessData!} enableCountersign={true} />
      default:
        return <></>
    }
  }

  return (
    <div id="check-summary-view-wrapper" className="check-summary-view-wrapper box">
      <div className="compliance-container">
        <div className="container-content centralize-content">
          {(activeStep !== 0) ? 
          <IconButton id="backArrow" color="inherit" className={classes.disableRipple} disabled={activeStep === 0}
            disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
            onClick={() => handleBackStep()}>
            <ArrowBackIcon fontSize="large" />
          </IconButton> : "" }
          <div className="container-content-form">
            <div className="editor-title">
              {(dbsUIContent.steps[activeStep] && dbsUIContent.steps[activeStep].title !== "") ? dbsUIContent.steps[activeStep].title : ""}
            </div>
            <div className="editor-forms" style={{paddingLeft: (activeStep === 0) ? "58px" : "0px", paddingRight: (activeStep === dbsUIContent.steps.length - 1) ? "58px" : "0px"}}>
              {loadStepContainer(dbsUIContent.steps[activeStep].editor!)}
            </div>
          </div>
          {(activeStep !== dbsUIContent.steps.length - 1) ? 
          <IconButton color="inherit" className={classes.disableRipple} disabled={activeStep === dbsUIContent.steps.length - 1}
            disableRipple={true} disableFocusRipple={true} disableTouchRipple={true}
            onClick={() => handleNextStep()}>
            <ArrowForwardIcon fontSize="large" />
          </IconButton> : "" }
        </div>
      </div>
      <div className="container-content">
      </div>
    </div>
  );
}