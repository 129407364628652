import GenericModal from "../../Components/GenericModal";
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import moment from "moment";

interface RequestChangeModalProps {
    toggleModal: () => void;
    isOpen: boolean;
    complianceProcessData: IComplianceProcess;
}

const CheckAuditLogModal = (props: RequestChangeModalProps) => {
    const { t } = useTranslation();
    
    return (
        <GenericModal
            heading={t("check_details.audit_log.title")}
            onCloseModal={props.toggleModal}
            open={props.isOpen}
            closeOnBackDrop={true}
            modalWidth={"750px"}
            modalHeight={"400px"}
            modalBottom={"30%"}
            modalPadding={"20px 40px 20px 40px"}
            className="request-change-modal"
        >
            <>
            <TableContainer component={Paper} className="scroll-style">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        <TableCell>{t("check_details.audit_log.header_title_event")}</TableCell>
                        <TableCell>{t("check_details.audit_log.header_title_requested_by")}</TableCell>
                        <TableCell>{t("check_details.audit_log.header_title_requested_on")}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.complianceProcessData.audit!.map((row, index) => (
                        <TableRow key={index} >
                            <TableCell component="th" scope="row" width="200px">{row.event}</TableCell>
                            <TableCell width="300px">{row.requestedBy}</TableCell>
                            <TableCell width="150px">{moment(row.requestedOn).format("DD/MM/YYYY")}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </>
        </GenericModal>
    )
}

export default CheckAuditLogModal