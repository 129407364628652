import Constants from "../../Constants/constants";
import "./index.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DocViewer, {DocViewerRenderers, IHeaderConfig, IConfig} from "react-doc-viewer";
import InfoTypes from "../../interfaces/InfoTypes";

interface DocViewerProps {
  document: InfoTypes.PreviewDocumentType;
}

const docViewerHeaderConfig: IHeaderConfig = {
  disableHeader: true,
  disableFileName: true,
}

const docViewerConfig: IConfig = {
  header: docViewerHeaderConfig
}

const DocumentViewer = (props: DocViewerProps) => {
  var isAllowedFileType = props.document.fileType ? (Constants.VALID_PREVIEW_FILE_TYPES.includes(props.document.fileType!)) : false;

  return isAllowedFileType ? (
    <div className="file-image-container">
      <DocViewer className="doc-vwr" pluginRenderers={ DocViewerRenderers } documents={props.document.documentURIs} config={docViewerConfig}  />
    </div>
  ) : (
    <div className="file-status-bar" key={"preview-doc"}>
      <div>
        <div className="file-type-logo">
          <CheckCircleIcon htmlColor={"#0da931"} style={{ margin: "10px" }} />
        </div>
        <span style={{ cursor: "pointer" }} className={`file-name `}>
          {props.document.fileName}
        </span>
      </div>
    </div>
  );
};

export default DocumentViewer;
