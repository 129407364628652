import GenericModal from "../../Components/GenericModal";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import GenericSelect from "../../Components/GenericSelect";
import ICheckRequest from "../../interfaces/ICheckRequest";
import AntSwitch from "../../Components/AntSwitch";
import IDBSRequestChangePatchRequest from "../../interfaces/IDBSRequestChangePatchRequest";
import secureFetch from "../../Services/secureFetch";
import Constants from "../../Constants/constants";
import GenericButton from "../../Components/GenericButton";

interface DBSRequestChangeDetailsProps {
    dbsDetails: ICheckRequest;
    checkId: string,
    toggleModal: () => void,
    isOpen: boolean,
    onCloseModal: (isSuccess: boolean, operation?: string) => void;
}

const InitialPutRequest: IDBSRequestChangePatchRequest = {
    dbsCheckType: "Standard",
    workforceType: "Other",
    isVolunteer: false,
    covid19FastTrackRequired: false,
    worksFromHome: false,
    childrensBarredList: false,
    adultsBarredList: false
}

export default function RequestChangeDbsDetailsModal(props: DBSRequestChangeDetailsProps) {
    const { t } = useTranslation();

    const checkTypes = [
        { title: "Standard", id: 1 },
        { title: "Enhanced", id: 2 }
    ]

    const [callSubmit, setCallSubmit] = useState(false);

    const workforceTypes = [
        { title: "Other", id: 1 },
        { title: "ChildWorkforce", id: 2 },
        { title: "AdultWorkforce", id: 3 },
        { title: "ChildAndAdultWorkforce", id: 4 }
    ]

    const getDropdownValue = (dropdownArray: any, selectedItem: string) => {
        return dropdownArray.find((item: any) => props.dbsDetails ?
            item.title === props.dbsDetails![selectedItem as keyof ICheckRequest] : []);
    }

    const handleRadioChanged = (event: React.ChangeEvent<HTMLInputElement>, value: any, name: string) => {
        if (name === 'isVolunteer') {
            setIsVolunteer(value)
        } else if (name === 'worksFromHome') {
            setWorksFromHome(value)
        } else if (name === 'covid19FastTrackRequired') {
            setCovid19FastTrackRequired(value)
        } else if (name === 'childrensBarredList') {
            setChildrensBarredList(value)
        } else if (name === 'adultsBarredList') {
            setAdultsBarredList(value)
        }
    }

    const handleCheckTypeChange = (event: React.ChangeEvent<{}>, value: any, name: string) => {
        if (name === 'workforceVal') {
            setWorkForceValue(value)
        } else if (name === 'checkVal') {
            setCheckValue(value)
        }
    }

    const [workforceVal, setWorkForceValue] = useState(getDropdownValue(workforceTypes, 'workforceType'));
    const [checkVal, setCheckValue] = useState(getDropdownValue(checkTypes, "dbsCheckType"));

    const [isVolunteer, setIsVolunteer] = useState(false);
    const [covid19FastTrackRequired, setCovid19FastTrackRequired] = useState(false);
    const [worksFromHome, setWorksFromHome] = useState(false);
    const [childrensBarredList, setChildrensBarredList] = useState(false);
    const [adultsBarredList, setAdultsBarredList] = useState(false);

    const [putRequested, setPutRequest] = useState(InitialPutRequest);

    const SendPut = async (putRequest: IDBSRequestChangePatchRequest) => {
        let requestUrl = `${Constants.API_URLS.DBS_SUFFIX}${props.checkId}${Constants.API_URLS.PUT_COUNTERSIGINING_DETAILS}`;
        secureFetch(requestUrl, {
            method: "PUT",
            body: putRequest
        }).then((res) => {
            if (res.ok) {
                props.onCloseModal(true, Constants.API_CALLS.REQUEST_DBS_CHANGE);
            } else {
                props.onCloseModal(false);
            }
        }).catch((error) => {
            props.onCloseModal(false);
        })
        props.toggleModal();
    }

    useEffect(() => {
        async function SendPatchRequest() {
            if (callSubmit) {
                await SendPut(putRequested);
            }
        }
        SendPatchRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callSubmit]);


    useEffect(() => {
        setIsVolunteer(props.dbsDetails.isVolunteer!)
        setCovid19FastTrackRequired(props.dbsDetails.covid19FastTrackRequired!)
        setWorksFromHome(props.dbsDetails.worksFromHome!)
        setChildrensBarredList(props.dbsDetails.childrensBarredList!)
        setAdultsBarredList(props.dbsDetails.adultsBarredList!)
        
        }, [props.dbsDetails])

    const handleSubmit = () => {
        setCallSubmit(true);
        let patchRequest: IDBSRequestChangePatchRequest = {
            dbsCheckType: checkVal.title,
            workforceType: workforceVal.title,
            isVolunteer: isVolunteer,
            covid19FastTrackRequired: covid19FastTrackRequired,
            worksFromHome: worksFromHome,
            childrensBarredList: childrensBarredList,
            adultsBarredList: adultsBarredList
        };
        setPutRequest(patchRequest);
    }

    return (
        <GenericModal
            heading={t("check_summary.edit_title")}
            onCloseModal={props.toggleModal}
            open={props.isOpen}
            closeOnBackDrop={true}
            modalWidth={"1000px"}
            modalHeight={"500px"}
            modalBottom={"30%"}
            modalPadding={"29px 60px 0px 60px"}
            className="request-change-modal">
            {props.dbsDetails ?
                <div id="check-header">
                    <div className="request-details-content">
                        <Grid container spacing={2} className="header-info">
                            <Grid item xs={12} md={4} className="align-self-end">
                                <GenericSelect
                                    id="levelsOfCheck"
                                    value={checkVal}
                                    dataSet={checkTypes}
                                    fullWidth={true}
                                    handleChange={(ev, value) => handleCheckTypeChange(ev, value, 'checkVal')}
                                    multiple={false}
                                    freeSolo={false}
                                    label={t("check_details.request_details.level_of_check_required")}
                                    error={false}
                                    renderChip={false}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className="align-self-end">
                                <GenericSelect
                                    id="workforceTypes"
                                    value={workforceVal}
                                    dataSet={workforceTypes}
                                    fullWidth={true}
                                    handleChange={(ev, value) => handleCheckTypeChange(ev, value, 'workforceVal')}
                                    multiple={false}
                                    freeSolo={false}
                                    label={t("check_details.request_details.workforce_type")}
                                    error={false}
                                    renderChip={false}
                                    required={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="header-info">
                            <Grid item xs={12} md={4} className="align-self-end">
                                <div className="edit-container-labels">{t("check_details.request_details.q1")}
                                    <div className="check-box-ctrls">
                                        <AntSwitch payload={{
                                            checked: covid19FastTrackRequired,
                                            onLabel: t("common.yes"),
                                            offLabel: t("common.no"),
                                            handleChange: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { handleRadioChanged(ev, checked, "covid19FastTrackRequired") }
                                        }}>
                                        </AntSwitch>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="align-self-end">
                                <div className="edit-container-labels">{t("check_details.request_details.q2")}
                                    <div className="check-box-ctrls">
                                        <AntSwitch payload={{
                                            checked: isVolunteer,
                                            onLabel: t("common.yes"),
                                            offLabel: t("common.no"),
                                            handleChange: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { handleRadioChanged(ev, checked, "isVolunteer") }
                                        }}>
                                        </AntSwitch>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="align-self-end">
                                <div className="edit-container-labels">{t("check_details.request_details.q3")}
                                    <div className="check-box-ctrls">
                                        <AntSwitch payload={{
                                            checked: worksFromHome,
                                            onLabel: t("common.yes"),
                                            offLabel: t("common.no"),
                                            handleChange: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { handleRadioChanged(ev, checked, "worksFromHome") }
                                        }}>
                                        </AntSwitch>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="align-self-end">
                                <div className="edit-container-labels">{t("check_details.request_details.q4")}
                                    <div className="check-box-ctrls">
                                        <AntSwitch payload={{
                                            checked: adultsBarredList,
                                            onLabel: t("common.yes"),
                                            offLabel: t("common.no"),
                                            handleChange: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { handleRadioChanged(ev, checked, "adultsBarredList") }
                                        }}>
                                        </AntSwitch>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className="align-self-end">
                                <div className="edit-container-labels">{t("check_details.request_details.q5")}
                                    <div className="check-box-ctrls">
                                        <AntSwitch payload={{
                                            checked: childrensBarredList,
                                            onLabel: t("common.yes"),
                                            offLabel: t("common.no"),
                                            handleChange: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => { handleRadioChanged(ev, checked, "childrensBarredList") }
                                        }}>
                                        </AntSwitch>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <GenericButton
                        onClick={handleSubmit}
                        type="success"
                        disabled={false}
                        style={{ backgroundColor: '#93E088', borderColor: '#93E088', marginLeft: "auto", marginTop: "30px" }}
                        buttonText={t("common.save")}
                    ></GenericButton>
                </div> :
                <div> Dbs details not available</div>
            }
        </GenericModal >
    )
}