import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericDataShow from "../../../Components/GenericDataShow";
import IComplianceProcess from "../../../interfaces/IComplianceProcess";
import IDocumentDefinition from "../../../interfaces/IDocumentDefinition";
import InfoTypes from "../../../interfaces/InfoTypes";
import "./index.scss";

import DocumentViewer from "../../../Components/DocumentViewer";
import Constants from "../../../Constants/constants";
import secureFetch from "../../../Services/secureFetch";
import {
  GetCountryDescription,
  RenderRTWExternalLinkToVerifyIdentity,
} from "../../../Utils/referenceDataService";
import IDocumentFields from "../../../interfaces/IDocumentFields";

interface VerifyIdentityProps {
  uiContent?: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
}

interface DocGroupState {
  primaryDocuments: InfoTypes.DocumentGroupDefinition;
  secondaryDocuments: InfoTypes.DocumentGroupDefinition;
  ternaryDocuments: InfoTypes.DocumentGroupDefinition;
}
interface DocGroupFieldsState {
  primaryDocuments?: IDocumentFields;
  secondaryDocuments?: IDocumentFields;
  ternaryDocuments?: IDocumentFields;
}

const InitialDocGroupFieldsState = {
  primaryDocuments: {},
  secondaryDocuments: {},
  ternaryDocuments: {},
};

export default function VerifyIdentity(props: VerifyIdentityProps) {
  const { t } = useTranslation();
  const [documentDefinitionData, setDocumentDefinitionData] =
    useState<IDocumentDefinition[]>();
  const [documentDBS, setDocumentDBS] = useState<any[]>();
  const [documentTypeFields, setDocumentTypeFields] =
    useState<DocGroupFieldsState>(InitialDocGroupFieldsState);

  const renderExternalLinkForRTWCheck: boolean =
    RenderRTWExternalLinkToVerifyIdentity(props.complianceProcessData) || false;

  useEffect(() => {
    let docDefData: IDocumentDefinition[];

    if (props.complianceProcessData?.processData?.documents) {
      docDefData = props.complianceProcessData.processData.documents;
    }
    if (docDefData! && docDefData[0]) {
      GetDocumentGroupSelectedLabels("primaryDocuments", docDefData[0].type!);
      if (docDefData[0].url) {
        docDefData[0].previewDocumentData = getPreviewDocumentData(
          docDefData[0]
        );
      }
    }
    setTimeout(() => {
      if (docDefData! && docDefData[1]) {
        GetDocumentGroupSelectedLabels(
          "secondaryDocuments",
          docDefData[1].type!
        );
        if (docDefData[1].url) {
          docDefData[1].previewDocumentData = getPreviewDocumentData(
            docDefData[1]
          );
        }
      }
    }, 0);
    if (docDefData! && docDefData![2]) {
      GetDocumentGroupSelectedLabels("ternaryDocuments", docDefData[2].type!);
      if (docDefData[2].url) {
        docDefData[2].previewDocumentData = getPreviewDocumentData(
          docDefData[2]
        );
      }
    }
    setDocumentDefinitionData(docDefData!);
  }, [props.complianceProcessData]);

  const getPreviewDocumentData = (documentDefinition: IDocumentDefinition) => {
    var prwDocument: InfoTypes.PreviewDocumentType = {
      fileName: documentDefinition!.filename!,
      fileType: documentDefinition!.filename
        ? documentDefinition.filename.split(".").pop()
        : "",
      documentURIs: [
        {
          uri: documentDefinition!.url!,
        },
      ],
    };
    return prwDocument;
  };

  const handleDownloadSavedFile = (index: number) => {
    const newWindow = window.open(
      documentDefinitionData![index].url,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const GetDocumentGroupSelectedLabels = (
    prop: keyof DocGroupState,
    docType: string
  ) => {
    var docGrpTypeFields = documentTypeFields;
    secureFetch(
      Constants.API_URLS.DOCUMENTS +
        docType +
        "/" +
        Constants.API_URLS.GET_DOCUMENT_TYPE_FIELDS,
      {
        method: "GET",
        body: "",
      }
    ).then((res) => {
      if (res && res !== undefined) {
        docGrpTypeFields![prop] = res;
        setDocumentTypeFields((items) => ({
          ...items,
          [prop]: res,
        }));
      }
    });
  };
  useEffect(() => {
    secureFetch(
      `${Constants.API_URLS.DOCUMENTS}dbs/${
        props.complianceProcessData.processData?.personal?.currentNationality ??
        "gbr"
      }`,
      {
        method: "GET",
        body: "",
      }
    ).then((res) => {
      if (res && res !== undefined) {
        setDocumentDBS([
          ...res.primaryDocuments,
          ...res.secondaryDocuments,
          ...res.ternaryDocuments,
        ]);
      }
    });
  }, [props]);

  return (
    <>
      <div
        id="verify-identity"
        className="verify-identity editor-component container-max-height"
      >
        <div className="verify-identity-body">
          <div className="verify-identity-body-container">
            <div className="verify-identity-body-container-details-row">
              <div className="verify-identity-body-container-details-row-content">
                {props.uiContent!.content &&
                props.uiContent!.content.indexOf("</") !== -1 ? (
                  <div
                    className="editor-component-body"
                    dangerouslySetInnerHTML={{
                      __html: props.uiContent!.content.replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {documentDefinitionData === undefined ? (
              <div className="verify-identity-body-container-details-row">
                <div className="verify-identity-body-container-details-row-content">
                  <div className="no-data-label">
                    {t("common.no_data_provided")}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="verify-identity-body-container-details-row">
                  {documentDefinitionData &&
                  documentDefinitionData?.[0] !== undefined ? (
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <div
                          className="field-label"
                          style={{ minInlineSize: "max-content" }}
                        >
                          {t("verify_identity.document_type")}:{" "}
                        </div>
                        <div
                          className="value-label"
                          style={{ marginLeft: "15px" }}
                        >
                          {
                            documentDBS?.find(
                              (doc) =>
                                doc.id === documentDefinitionData[0].documentId
                            )?.description
                          }
                        </div>
                      </div>
                      <div className="doc-value-fields">
                        <div className="oneHalfWidth uploader-win">
                          {documentDefinitionData &&
                          documentDefinitionData[0] &&
                          documentDefinitionData[0].previewDocumentData ? (
                            <div
                              className="file-display-container"
                              style={{ minHeight: "160px", cursor: "pointer" }}
                              onClick={() => {
                                handleDownloadSavedFile(0);
                              }}
                            >
                              {
                                <DocumentViewer
                                  document={
                                    documentDefinitionData[0]
                                      .previewDocumentData
                                  }
                                />
                              }
                            </div>
                          ) : (
                            <div
                              className="file-display-container"
                              style={{ minHeight: "160px" }}
                            >
                              <div className="no-doc-container">
                                <div className="no-doc-text">
                                  {props.complianceProcessData
                                    .digitalIdentity &&
                                  props.complianceProcessData.digitalIdentity
                                    .importStatus === "Imported"
                                    ? t(
                                        "check_details.check_header.digital_identity"
                                      ) +
                                      " " +
                                      props.complianceProcessData
                                        .digitalIdentity.providerName
                                    : t("verify_identity.no_doc_text")}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="oneHalfWidth">
                          {documentDefinitionData &&
                          documentDefinitionData[0] &&
                          documentDefinitionData[0].identifier &&
                          documentDefinitionData[0].identifier !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.primaryDocuments &&
                                documentTypeFields.primaryDocuments
                                  .identifierText &&
                                documentTypeFields.primaryDocuments
                                  .identifierText !== ""
                                  ? documentTypeFields.primaryDocuments
                                      .identifierText
                                  : t("verify_identity.id")
                              }
                              value={documentDefinitionData[0].identifier}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[0] &&
                          documentDefinitionData[0].emittingCountry &&
                          documentDefinitionData[0].emittingCountry !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.primaryDocuments &&
                                documentTypeFields.primaryDocuments
                                  .emittingCountryText &&
                                documentTypeFields.primaryDocuments
                                  .emittingCountryText !== ""
                                  ? documentTypeFields.primaryDocuments
                                      .emittingCountryText
                                  : t("verify_identity.emmiting_country")
                              }
                              value={GetCountryDescription(
                                documentDefinitionData[0].emittingCountry!
                              )}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[0] &&
                          documentDefinitionData[0].emittingEntity &&
                          documentDefinitionData[0].emittingEntity !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.primaryDocuments &&
                                documentTypeFields.primaryDocuments
                                  .emittingEntityText &&
                                documentTypeFields.primaryDocuments
                                  .emittingEntityText !== ""
                                  ? documentTypeFields.primaryDocuments
                                      .emittingEntityText
                                  : t("verify_identity.emitting_entity")
                              }
                              value={documentDefinitionData[0].emittingEntity}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[0] &&
                          documentDefinitionData[0].validFrom ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.primaryDocuments &&
                                documentTypeFields.primaryDocuments
                                  .dateFromText &&
                                documentTypeFields.primaryDocuments
                                  .dateFromText !== ""
                                  ? documentTypeFields.primaryDocuments
                                      .dateFromText
                                  : t("common.valid_from")
                              }
                              value={moment(
                                documentDefinitionData[0].validFrom
                              ).format("DD/MM/YYYY")}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[0] &&
                          documentDefinitionData[0].validTo ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.primaryDocuments &&
                                documentTypeFields.primaryDocuments
                                  .dateToText &&
                                documentTypeFields.primaryDocuments
                                  .dateToText !== ""
                                  ? documentTypeFields.primaryDocuments
                                      .dateToText
                                  : t("common.valid_to")
                              }
                              value={moment(
                                documentDefinitionData[0].validTo
                              ).format("DD/MM/YYYY")}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="verify-identity-body-container-details-row">
                  {documentDefinitionData &&
                  documentDefinitionData?.[1] !== undefined ? (
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <div
                          className="field-label"
                          style={{ minInlineSize: "max-content" }}
                        >
                          {t("verify_identity.document_type")}:{" "}
                        </div>
                        <div
                          className="value-label"
                          style={{ marginLeft: "15px" }}
                        >
                          {
                            documentDBS?.find(
                              (doc) =>
                                doc.id === documentDefinitionData[1].documentId
                            )?.description
                          }
                        </div>
                      </div>
                      <div className="doc-value-fields">
                        <div className="oneHalfWidth uploader-win">
                          {documentDefinitionData &&
                          documentDefinitionData[1] &&
                          documentDefinitionData[1].previewDocumentData ? (
                            <div
                              className="file-display-container"
                              style={{ minHeight: "160px", cursor: "pointer" }}
                              onClick={() => {
                                handleDownloadSavedFile(1);
                              }}
                            >
                              {
                                <DocumentViewer
                                  document={
                                    documentDefinitionData[1]
                                      .previewDocumentData
                                  }
                                />
                              }
                            </div>
                          ) : (
                            <div
                              className="file-display-container"
                              style={{ minHeight: "160px" }}
                            >
                              <div className="no-doc-container">
                                <div className="no-doc-text">
                                  {props.complianceProcessData
                                    .digitalIdentity &&
                                  props.complianceProcessData.digitalIdentity
                                    .importStatus === "Imported"
                                    ? t(
                                        "check_details.check_header.digital_identity"
                                      ) +
                                      " " +
                                      props.complianceProcessData
                                        .digitalIdentity.providerName
                                    : t("verify_identity.no_doc_text")}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="oneHalfWidth">
                          {documentDefinitionData &&
                          documentDefinitionData[1] &&
                          documentDefinitionData[1].identifier &&
                          documentDefinitionData[1].identifier !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.secondaryDocuments &&
                                documentTypeFields.secondaryDocuments
                                  .identifierText &&
                                documentTypeFields.secondaryDocuments
                                  .identifierText !== ""
                                  ? documentTypeFields.secondaryDocuments
                                      .identifierText
                                  : t("verify_identity.id")
                              }
                              value={documentDefinitionData[1].identifier}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[1] &&
                          documentDefinitionData[1].emittingCountry &&
                          documentDefinitionData[1].emittingCountry !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.secondaryDocuments &&
                                documentTypeFields.secondaryDocuments
                                  .emittingCountryText &&
                                documentTypeFields.secondaryDocuments
                                  .emittingCountryText !== ""
                                  ? documentTypeFields.secondaryDocuments
                                      .emittingCountryText
                                  : t("verify_identity.emmiting_country")
                              }
                              value={GetCountryDescription(
                                documentDefinitionData[1].emittingCountry
                              )}
                              isSelectField
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[1] &&
                          documentDefinitionData[1].emittingEntity &&
                          documentDefinitionData[1].emittingEntity !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.secondaryDocuments &&
                                documentTypeFields.secondaryDocuments
                                  .emittingEntityText &&
                                documentTypeFields.secondaryDocuments
                                  .emittingEntityText !== ""
                                  ? documentTypeFields.secondaryDocuments
                                      .emittingEntityText
                                  : t("verify_identity.emitting_entity")
                              }
                              value={documentDefinitionData[1].emittingEntity}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[1] &&
                          documentDefinitionData[1].validFrom ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.secondaryDocuments &&
                                documentTypeFields.secondaryDocuments
                                  .dateFromText &&
                                documentTypeFields.secondaryDocuments
                                  .dateFromText !== ""
                                  ? documentTypeFields.secondaryDocuments
                                      .dateFromText
                                  : t("common.valid_from")
                              }
                              value={moment(
                                documentDefinitionData[1].validFrom
                              ).format("DD/MM/YYYY")}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[1] &&
                          documentDefinitionData[1].validTo ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.secondaryDocuments &&
                                documentTypeFields.secondaryDocuments
                                  .dateToText &&
                                documentTypeFields.secondaryDocuments
                                  .dateToText !== ""
                                  ? documentTypeFields.secondaryDocuments
                                      .dateToText
                                  : t("common.valid_to")
                              }
                              value={moment(
                                documentDefinitionData[1].validTo
                              ).format("DD/MM/YYYY")}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {documentDefinitionData &&
                  documentDefinitionData?.[2] !== undefined ? (
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <div
                          className="field-label"
                          style={{ minInlineSize: "max-content" }}
                        >
                          {t("verify_identity.document_type")}:{" "}
                        </div>
                        <div
                          className="value-label"
                          style={{ marginLeft: "15px" }}
                        >
                          {
                            documentDBS?.find(
                              (doc) =>
                                doc.id === documentDefinitionData[2].documentId
                            )?.description
                          }
                        </div>
                      </div>
                      <div className="doc-value-fields">
                        <div className="oneHalfWidth uploader-win">
                          {documentDefinitionData &&
                          documentDefinitionData[2] &&
                          documentDefinitionData[2].previewDocumentData ? (
                            <div
                              className="file-display-container"
                              style={{ minHeight: "160px", cursor: "pointer" }}
                              onClick={() => {
                                handleDownloadSavedFile(2);
                              }}
                            >
                              {
                                <DocumentViewer
                                  document={
                                    documentDefinitionData[2]
                                      .previewDocumentData
                                  }
                                />
                              }
                            </div>
                          ) : (
                            <div
                              className="file-display-container"
                              style={{ minHeight: "160px" }}
                            >
                              <div className="no-doc-container">
                                <div className="no-doc-text">
                                  {props.complianceProcessData
                                    .digitalIdentity &&
                                  props.complianceProcessData.digitalIdentity
                                    .importStatus === "Imported"
                                    ? t(
                                        "check_details.check_header.digital_identity"
                                      ) +
                                      " " +
                                      props.complianceProcessData
                                        .digitalIdentity.providerName
                                    : t("verify_identity.no_doc_text")}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="oneHalfWidth">
                          {documentDefinitionData &&
                          documentDefinitionData[2] &&
                          documentDefinitionData[2].identifier &&
                          documentDefinitionData[2].identifier !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.ternaryDocuments &&
                                documentTypeFields.ternaryDocuments
                                  .identifierText &&
                                documentTypeFields.ternaryDocuments
                                  .identifierText !== ""
                                  ? documentTypeFields.ternaryDocuments
                                      .identifierText
                                  : t("verify_identity.id")
                              }
                              value={documentDefinitionData[2].identifier}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[2] &&
                          documentDefinitionData[2].emittingCountry &&
                          documentDefinitionData[2].emittingCountry !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.ternaryDocuments &&
                                documentTypeFields.ternaryDocuments
                                  .emittingCountryText &&
                                documentTypeFields.ternaryDocuments
                                  .emittingCountryText !== ""
                                  ? documentTypeFields.ternaryDocuments
                                      .emittingCountryText
                                  : t("verify_identity.emmiting_country")
                              }
                              value={GetCountryDescription(
                                documentDefinitionData[2].emittingCountry
                              )}
                              isSelectField
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[2] &&
                          documentDefinitionData[2].emittingEntity &&
                          documentDefinitionData[2].emittingEntity !== "" ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.ternaryDocuments &&
                                documentTypeFields.ternaryDocuments
                                  .emittingEntityText &&
                                documentTypeFields.ternaryDocuments
                                  .emittingEntityText !== ""
                                  ? documentTypeFields.ternaryDocuments
                                      .emittingEntityText
                                  : t("verify_identity.emitting_entity")
                              }
                              value={documentDefinitionData[2].emittingEntity}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[2] &&
                          documentDefinitionData[2].validFrom ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.ternaryDocuments &&
                                documentTypeFields.ternaryDocuments
                                  .dateFromText &&
                                documentTypeFields.ternaryDocuments
                                  .dateFromText !== ""
                                  ? documentTypeFields.ternaryDocuments
                                      .dateFromText
                                  : t("common.valid_from")
                              }
                              value={moment(
                                documentDefinitionData[2].validFrom
                              ).format("DD/MM/YYYY")}
                            />
                          ) : (
                            ""
                          )}
                          {documentDefinitionData &&
                          documentDefinitionData[2] &&
                          documentDefinitionData[2].validTo ? (
                            <GenericDataShow
                              label={
                                documentTypeFields &&
                                documentTypeFields.ternaryDocuments &&
                                documentTypeFields.ternaryDocuments
                                  .dateToText &&
                                documentTypeFields.ternaryDocuments
                                  .dateToText !== ""
                                  ? documentTypeFields.ternaryDocuments
                                      .dateToText
                                  : t("common.valid_to")
                              }
                              value={moment(
                                documentDefinitionData[2].validTo
                              ).format("DD/MM/YYYY")}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {renderExternalLinkForRTWCheck && (
                    <div className="verify-identity-body-container-details-row-content">
                      <div className="docGrpControl">
                        <div
                          className="field-label"
                          style={{ minInlineSize: "max-content" }}
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "verify_identity.rtw_external_link"
                            ).replace(/(<? *script)/gi, "illegalscript"),
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
