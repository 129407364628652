import "../CheckDetails/index.scss";
import { Fab } from 'react-tiny-fab';
import AddIcon from '@material-ui/icons/Add';
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import DBSActions from "./DBSActions";
import CommonActions from "./CommonActions";
import { ComplianceTypes } from "../../Constants/enums";
import RTWActions from "./RTWActions";
import IdentityCheckActions from "./IDCheckActions";

interface ICheckActionProps {
    complianceProcessData: IComplianceProcess;
    userRoles: string[];
    handleRequestChangeWithComments?: () => void;
    handleAcceptByClient: () => void;
    handleCancelCheck: () => void;
    handleRequestChange: () => void;
    handleAcceptByCounterSigner: () => void;
    handleViewAuditTrail: () => void;
    handleCompleteCheck: () => void;
    handleRightToWorkFlagOnCheck: (hasRightToWork: boolean) => void;
    handleIdentityFlagOnCheck: (confirm: boolean) => void;
    handleResetYotiSession: () => void;
}

export default function CheckActions(props: ICheckActionProps) {

  const loadComplianceTypeCheckActions = () => {
    if (props.complianceProcessData) {
      switch (props.complianceProcessData.complianceType) {
        case ComplianceTypes.DBS:
          return <DBSActions 
                  complianceProcessData={props.complianceProcessData} 
                  userRoles={props.userRoles}
                  handleAcceptByClient={props.handleAcceptByClient} 
                  handleAcceptByCounterSigner={props.handleAcceptByCounterSigner}
                  handleRequestChange={props.handleRequestChange}
                  handleRequestChangeWithComments={props.handleRequestChangeWithComments} />

        case ComplianceTypes.IdentityAndRWAudit:
          return <RTWActions 
                  complianceProcessData={props.complianceProcessData} 
                  handleRightToWorkFlagOnCheck={props.handleRightToWorkFlagOnCheck} />
        
        case ComplianceTypes.IdentityCheck:
          return <></>
          // Note: Commented out ID check - Check Actions for now
          // return <IdentityCheckActions
          //         complianceProcessData={props.complianceProcessData}
          //         handleIdentityFlagOnCheck={props.handleIdentityFlagOnCheck} />
      }
    } else {
      return <></>
    }
  }

    return (
        <div className="request-details-content">
          <Fab
            mainButtonStyles={{ backgroundColor: "#2D7FF9" }}
            style={{ top: 5, right: 5 }}
            icon={<AddIcon />}
            event="hover"
            alwaysShowTitle={false}
          >
            <div className="check-action-option-btn">
              {loadComplianceTypeCheckActions()}
              <CommonActions 
                complianceProcessData={props.complianceProcessData}
                handleCancelCheck={props.handleCancelCheck}
                handleViewAuditTrail={props.handleViewAuditTrail}
                handleResetYotiSession={props.handleResetYotiSession}
                userRoles={props.userRoles}
              />
            </div>
          </Fab>
      </div>
    )
}