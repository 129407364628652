import { useTranslation } from "react-i18next";
import { Action } from 'react-tiny-fab';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import { CheckHasClientActions, CheckHasCounterSignerActions, IsAdminEmail } from "../../Utils/referenceDataService";

interface IDBSActionSProps {
    complianceProcessData: IComplianceProcess;
    userRoles: string[];
    handleRequestChangeWithComments?: () => void;
    handleAcceptByClient: () => void;
    handleRequestChange: () => void;
    handleAcceptByCounterSigner: () => void;
}

export default function DBSActions(props: IDBSActionSProps) {
    const { t } = useTranslation();

    const hasClientActions: boolean = CheckHasClientActions(props.complianceProcessData) || false;
    const hasCounterSignerActions: boolean = CheckHasCounterSignerActions(props.complianceProcessData, props.userRoles) || false;
    const isAdminEmail: boolean = IsAdminEmail() || false;
    
    return (
        <>
        {(hasClientActions || isAdminEmail) &&
          <>
            <Action
              text={t("check_summary.request_change")}
              onClick={props.handleRequestChangeWithComments}>
              <div className="actn-btn">
                <div className="span-txt">
                  <span>{t("check_summary.request_change")}</span>
                </div>
                <div className="svg-el">
                    <EditIcon />
                </div>
              </div>
            </Action>
            <Action
              text={t("common.countersign")}
              onClick={props.handleAcceptByClient}>
              <div className="actn-btn">
                <div className="span-txt">
                  <span>{t("common.countersign")}</span>
                </div>
                <div className="svg-el">
                    <PlaylistAddCheckIcon />
                </div>
              </div>
            </Action>
          </>
        }

        {hasCounterSignerActions &&
        <>
            <Action
              text={t("check_summary.edit_title")}
              onClick={props.handleRequestChange}>
              <div className="actn-btn">
                <div className="span-txt">
                  <span>{t("check_summary.edit_title")}</span>
                </div>
                <div className="svg-el">
                    <EditIcon />
                </div>
              </div>
            </Action>
            <Action
              text={t("check_summary.submit_to_dbs")}
              onClick={props.handleAcceptByCounterSigner}>
              <div className="actn-btn">
                <div className="span-txt">
                  <span>{t("check_summary.submit_to_dbs")}</span>
                </div>
                <div className="svg-el">
                  <AssignmentTurnedInIcon />
                </div>
              </div>
            </Action>
        </>
        }
      </>
    )
}