import { BrowserRouter, Switch } from "react-router-dom";
import "./routes.scss";
import Dashboard from "../Screens/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import LogoutButton from "../Auth/Logout";
import Layout from "../Components/Layout";
import Constants from "../Constants/constants";
import OtherLayout from "../Components/OtherLayout";
import CheckSummaryView from "../Screens/CheckSummaryView";
import CheckDetails from "../Screens/CheckDetails";
import { useHistory } from "react-router-dom";
import OoopsImage from "../Assets/Ooops.png";
import { useTranslation } from "react-i18next";
import GenericButton from "../Components/GenericButton";
import { reactPlugin } from '../appInsights';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

function Routes() {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<BrowserRouter basename="/" forceRefresh={true}>
			<div className='routes-container'>
				<AppInsightsErrorBoundary onError={() => (
						<div className="error-container">
							<img className="oops-img" alt="Something Went Wrong" src={OoopsImage} />
							<div className="text-container">
								<div className="message-text">{t("errors.ooops_something_wrong")}</div>
								<GenericButton
									onClick={() => {
										history.goBack();
									}}
									disabled={false}
									type="primary"
									buttonText={t("errors.return")}
								></GenericButton>
							</div>
						</div>
						)}  appInsights={reactPlugin}>
					<Switch>
						<ProtectedRoute
							exact
							path={Constants.APP_ROUTES.DASHBORAD}
							component={()=> (
								<Layout>
									<Dashboard />
								</Layout>  
								)}
						></ProtectedRoute>
						<ProtectedRoute
							exact
							path={Constants.APP_ROUTES.CHECK_SUMMARY_VIEW}
							component={()=> (
								<OtherLayout>
								<CheckSummaryView />
								</OtherLayout>  
							)}
						></ProtectedRoute>
						<ProtectedRoute
							exact
							path={Constants.APP_ROUTES.CHECK_DETAILS + "/:id/:source"}
							component={()=> (
								<OtherLayout>
								<CheckDetails />
								</OtherLayout>  
							)}
						></ProtectedRoute>
						<ProtectedRoute
							exact
							path="/logout"
							component={LogoutButton}
						></ProtectedRoute>
					</Switch>
				</AppInsightsErrorBoundary>
			</div>
		</BrowserRouter>
	);
}

export default Routes;