import React, { useEffect, useState } from "react";
import "./index.scss";
import { RouteComponentProps } from "react-router-dom";
import IAddressInfo from "../../../interfaces/IAddressInfo";
import moment from "moment";
import _ from "lodash";
import GenericDataShow from "../../../Components/GenericDataShow";
import InfoTypes from "../../../interfaces/InfoTypes";
import IComplianceProcess from "../../../interfaces/IComplianceProcess";
import PreviousAddress from "./PreviousAddress";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "../../../Components/LoaderModal";
import { GetCountryDescription } from "../../../Utils/referenceDataService";

interface AddressHistoryProps
  extends Partial<RouteComponentProps<any>> {
  uiContent: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
}

const InitialAddressState: InfoTypes.AddressInfo = {
  building: "",
  country: "",
  county: "",
  residentFrom: null,
  residentTo: null,
  postCode: "",
  street: "",
  town: "",
  isNew: true,
}

const InitialAddressesState: IAddressInfo = {
  currentAddress: InitialAddressState,
  previousAddresses: [InitialAddressState]
};

export default function AddressHistory(props: AddressHistoryProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    var addressData: IAddressInfo;
    if (props.complianceProcessData && props.complianceProcessData.processData && props.complianceProcessData.processData.addresses) {
      addressData = props.complianceProcessData.processData.addresses;
    } else {
      addressData = InitialAddressesState;
    }

    if (addressData!) {
      if (addressData.currentAddress) {
        var formattedAddress = addressData.currentAddress;

        if (!addressData.currentAddress.residentFrom) {
          formattedAddress.residentFrom = null;
        }
        if (!addressData.currentAddress.residentTo) {
          formattedAddress.residentTo = null;
        }
      }

      if (addressData?.previousAddresses && addressData?.previousAddresses.length > 0) {

        var formattedPrevAddresses = addressData.previousAddresses;
        var idx = 0;
        _.map(addressData.previousAddresses!, (prevAdd: InfoTypes.AddressInfo) => {
          if (!prevAdd.residentFrom) {
            prevAdd.residentFrom = null;
          }
          if (!prevAdd.residentTo) {
            prevAdd.residentTo = null;
          }
          formattedPrevAddresses[idx] = prevAdd;
        });

      }
    }
    setLoading(false);
  }, [props.complianceProcessData]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <div id="address-history" className="address-history editor-component container-max-height">
        <div className="address-history-body">
          <div className="address-history-body-container">
            <div className="address-history-body-container-details">
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("address_history.postal_code")}
                    value={props.complianceProcessData?.processData?.addresses?.currentAddress?.postCode || ""}
                  />
                </div>
                <div className="oneHalfWidth">

                </div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("address_history.building")}
                    value={props.complianceProcessData?.processData?.addresses?.currentAddress?.building || ""}
                  />
                </div>
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("address_history.street")}
                    value={props.complianceProcessData?.processData?.addresses?.currentAddress?.street || ""}
                  />
                </div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("address_history.town")}
                    value={props.complianceProcessData?.processData?.addresses?.currentAddress?.town}
                  />
                </div>
                <div className="oneHalfWidth">

                </div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("address_history.county")}
                    value={props.complianceProcessData?.processData?.addresses?.currentAddress?.county}
                  />
                </div>
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.country")}
                    value={(props.complianceProcessData?.processData?.addresses?.currentAddress?.country && props.complianceProcessData?.processData?.addresses?.currentAddress?.country !== "") ? GetCountryDescription(props.complianceProcessData?.processData?.addresses?.currentAddress?.country!) : ""}
                  />
                </div>
              </div>
              <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("address_history.resident_from")}
                    value={moment(props.complianceProcessData?.processData?.addresses?.currentAddress?.residentFrom).format("DD/MM/YYYY")}
                  />
                </div>
                <div className="oneHalfWidth">
                </div>
              </div>
            </div>
          </div>
          <div className="address-history-body-container">
            {(props.complianceProcessData?.processData?.addresses && props.complianceProcessData?.processData?.addresses?.previousAddresses!) ? (
              _.map(props.complianceProcessData?.processData?.addresses?.previousAddresses, (prevAdd: InfoTypes.AddressInfo, pAddressIdx: number) => (
                <>
                  <div className="address-history-body-container-title editor-component-title icon-btns">{t("address_history.prev_address")}:
                  </div>
                  <PreviousAddress key={`persona-prevName-${pAddressIdx}`} pAddressIdx={pAddressIdx} prevAddressData={prevAdd} />
                </>
              ))) : ""}
          </div>
          <div className="address-history-body-container-details-row-submit">
            <div className="container-submit-btn">
            </div>
          </div>
          <LoaderModal isLoading={loading} />
        </div>
      </div>
    </>
  );
}
