import React, { useEffect, useState } from "react";
import "./index.scss";
import _ from "lodash";
import IPersonalDetails from "../../../interfaces/IPersonalDetails";
import moment from "moment";
import GenericDataShow from "../../../Components/GenericDataShow";
import InfoTypes from "../../../interfaces/InfoTypes";
import IComplianceProcess from "../../../interfaces/IComplianceProcess";
import PreviousName from "./PreviousName";
import PreviousNationality from "./PreviousNationality";
import { useTranslation } from "react-i18next"
import { LoaderModal } from "../../../Components/LoaderModal";
import { GetCountryDescription, GetNationalityDescription } from "../../../Utils/referenceDataService";

interface PersonalInfoProps {
  uiContent: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
}

interface PersonalInfoState{
  title: string;
  titleVal?: InfoTypes.SelectValueType;
  gender: string;
  genderVal?: InfoTypes.SelectValueType;
  forename: string;
  middleNames: string[];
  surname: string;
  dateOfBirth: Date | null;
  currentNationality: string;
  currentNationalityVal?: InfoTypes.SelectValueType;
  country?: string;
  previousNames: PreviousNameState[];
  previousNationalities: PreviousNationalityState[];
}

interface PreviousNameState {
    forename: string;
    surname: string;
    from: string;
    to: string;
}

interface PreviousNationalityState {
    nationality: string;
    nationalityVal?: InfoTypes.SelectValueType;
    from: Date | null;
    to: Date | null;
}

const InitialPersonalInfoState: InfoTypes.PersonalDetails = {
  title: "",
  titleVal: {
    title: "",
    id: "",
    value: ""
  },
  gender: "",
  forename: "",
  middleNames: [""],
  surname: "",
  dateOfBirth: null,
  currentNationality: "",
  placeOfBirth: {
    country: "",
    town: "",
    county: ""
  },
  previousNames: [{
    forename: "",
    surname: "",
    from: "",
    to: "",
  }],
  previousNationalities: [{
    nationality: "",
    from: null,
    to: null,
  }],
}

export default function PersonalInfo (props: PersonalInfoProps, state: PersonalInfoState) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [personalDetailsValues, setPersonalDetailsValues] = useState<IPersonalDetails>();

  useEffect(() => {
    setLoading(true);
    let personalData: IPersonalDetails;
    if (props.complianceProcessData && props.complianceProcessData.processData && props.complianceProcessData.processData.personal) {
      personalData = props.complianceProcessData.processData.personal;
    } else{
      personalData = InitialPersonalInfoState;
    }

    if (personalData!) {
      if (!personalData.dateOfBirth) {
        personalData.dateOfBirth = null;
      }

      if (!personalData.middleNames) {
        personalData.middleNames = [];
      }
    }
    setPersonalDetailsValues(personalData);
    setLoading(false);
  }, [props.complianceProcessData])

  return (
    personalDetailsValues ? (
      <div id="personal-info" className="personal-info editor-component container-max-height">
        <div className="personal-info-body">
          <div className="personal-info-body-container">
            <div className="personal-info-body-container-details">
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.title")}
                    value={personalDetailsValues?.title}
                  />
                </div>
                <div className="oneHalfWidth">
                
                </div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.forename")}
                    value={personalDetailsValues?.forename}
                  />
                </div>
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.surname")}
                    value={personalDetailsValues?.surname}
                  />
                </div>
              </div>
              {personalDetailsValues && 
              (personalDetailsValues!.middleNames && personalDetailsValues?.middleNames.length > 0) ? (
                _.map(personalDetailsValues.middleNames!, (mName: string, index: number) => (
                  <div key={`personal-info-middleName-div-${index}`} className="personal-info-body-container-details-row">
                    <div className="oneHalfWidth" style={{display: "flex"}}>
                      {mName !== "" ? (
                      <GenericDataShow
                        label={t("common.middle_name")}
                        value={mName}
                        key={`persona-info-middleName-${index}`}
                      />
                      ) : ""}
                    </div>
                    <div className="oneHalfWidth" style={{display: "flex"}}></div>
                  </div>
                ))) : ""}
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.gender")}
                    value={personalDetailsValues?.gender}
                  />
                </div>
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.birthdate")}
                    value={personalDetailsValues?.dateOfBirth ? moment(personalDetailsValues?.dateOfBirth).format("DD/MM/YYYY") : ""}
                  />
               </div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("profile_details.town_of_birth")}
                    value={personalDetailsValues?.placeOfBirth?.town!}
                  />
                </div>
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.country")}
                    value={(personalDetailsValues?.placeOfBirth?.country && personalDetailsValues?.placeOfBirth?.country !== "") ? GetCountryDescription(personalDetailsValues?.placeOfBirth?.country!) : ""}
                  />
                </div>
              </div>
              <div className="personal-info-body-container-details-row">
                <div className="oneHalfWidth">
                  <GenericDataShow
                    label={t("common.current_nationality")}
                    value={(personalDetailsValues.currentNationality && personalDetailsValues.currentNationality !== "") ? GetNationalityDescription(personalDetailsValues.currentNationality!) : ""}
                  />
                </div>
                <div className="oneHalfWidth">
                </div>
              </div>
            </div>
          </div>
          <div className="personal-info-body-container">
            {personalDetailsValues! && personalDetailsValues?.previousNames ? (
              _.map(personalDetailsValues.previousNames, (prevName: InfoTypes.PreviousNames, pNameIdx: number) => (
                <div key={`persona-info-prevName-${pNameIdx}`}>
                  <div 
                    key={`persona-info-prevName-title-${pNameIdx}`}  
                    className="personal-info-body-container-title editor-component-title icon-btns">
                      {t("personal_information.prev_name")}
                  </div>
                  <PreviousName key={`persona-prevName-${pNameIdx}`} pNameIdx={pNameIdx} prevNameData={prevName} />
                </div>
              ))) : ""}
          </div>
          <div className="personal-info-body-container">
            {personalDetailsValues! && personalDetailsValues?.previousNationalities ? (
              _.map(personalDetailsValues?.previousNationalities, (prevNational: InfoTypes.PreviousNationalities, pNationalIdx: number) => (
                <div key={`persona-info-prevNationality-${pNationalIdx}`}>
                  <div 
                    key={`persona-info-prevNationality-title-${pNationalIdx}`} 
                    className="personal-info-body-container-title editor-component-title icon-btns"
                  >
                    {t("personal_information.prev_nationalities")}
                  </div>
                  <PreviousNationality key={`persona-prevNationality-${pNationalIdx}`} pNationalityIdx={pNationalIdx} prevNationalityData={prevNational} />
                </div>
              ))) : ""}
          </div>
          <div className="personal-info-body-container">
            <div className="personal-info-body-container-details-row-submit">
              <div className="container-submit-btn">
              </div>
            </div>
          </div>
          <LoaderModal isLoading={loading} />
        </div>
      </div>
    ) : (
      <></>
    )
  );
}
