import { DrawerItem } from '../Menu/DrawerItemType';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { PowerSettingsNew } from '@material-ui/icons';

export const DRAWER_LIST: DrawerItem[] = [
  {
    route: '/dashboard',
    literal: 'Dashboard',
    Icon: DashboardIcon,
  },
  {
    route: '/logout',
    literal: 'Log Out',
    styleName: "logout-item",
    Icon: PowerSettingsNew,
  },
];
