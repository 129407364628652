import "./index.scss";
import { useTranslation } from "react-i18next";
import ICheckHeader from "../../interfaces/ICheckHeader";
import { Grid } from "@material-ui/core";
import DBSRequestDetails from "./DBSRequestDetails";
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import RTWResultDetails from "./RTWResultDetails";
import {
  CanResetOrDisplayYotiSession,
  IsAdminEmail,
  RenderIdentityCheckStatus,
  RenderRightToWorkCheckStatus,
} from "../../Utils/referenceDataService";
import IDCheckResultDetails from "./IDCheckResultDetails";
import DBSResultDetails from "./DBSResultDetails";
import moment from "moment";

interface ICheckHeaderProps {
  checkHeader: ICheckHeader | null;
  complianceProcessData: IComplianceProcess;
}

export default function CheckHeader(props: ICheckHeaderProps) {
  const { t } = useTranslation();
  const showRightToWorkCheckStatus: boolean =
    RenderRightToWorkCheckStatus(props.complianceProcessData) || false;
  const showIdentityCheckStatus: boolean =
    RenderIdentityCheckStatus(props.complianceProcessData) || false;
  const canDisplayYotiSessionAndStatus: boolean =
    CanResetOrDisplayYotiSession(props.complianceProcessData) || false;
  const isAdminEmail: boolean = IsAdminEmail() || false;

  return (
    <div>
      <div id="check-details-header">
        <p className="check-details-header-title">
          {t("check_details.check_header.title")}
        </p>
        {props.checkHeader && (
          <Grid
            container
            className="header-info"
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {isAdminEmail && (
              <Grid item xs={12} className="align-self-end">
                <div className="container-details-row-labels">
                  {t("check_details.check_header.check_id")}:
                  <label className="container-details-row-value">
                    {" "}
                    {props.complianceProcessData.id}
                  </label>
                </div>
              </Grid>
            )}
            <Grid item xs={12} className="align-self-end">
              <div className="container-details-row-labels">
                {t("check_details.check_header.workerName")}:
                <label className="container-details-row-value">
                  {" "}
                  {props.checkHeader?.worker
                    ? props.checkHeader.worker
                    : t("common.not_set")}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} className="align-self-end">
              <div className="container-details-row-labels">
                {t("check_details.check_header.role")}:
                <label className="container-details-row-value">
                  {" "}
                  {props.checkHeader?.role
                    ? props.checkHeader.role
                    : t("common.not_set")}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} className="align-self-end">
              <div className="container-details-row-labels">
                {t("check_details.check_header.organisation")}:
                <label className="container-details-row-value">
                  {" "}
                  {props.checkHeader?.organisation
                    ? props.checkHeader.organisation
                    : t("common.not_set")}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} className="align-self-end">
              <div className="container-details-row-labels">
                {t("check_details.check_header.email")}:
                <label className="container-details-row-value">
                  {" "}
                  {props.checkHeader?.email
                    ? props.checkHeader.email
                    : t("common.not_set")}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} className="align-self-end">
              <div className="container-details-row-labels">
                {t("check_details.check_header.location")}:
                <label className="container-details-row-value">
                  {" "}
                  {props.checkHeader?.location
                    ? props.checkHeader.location
                    : t("common.not_set")}
                </label>
              </div>
            </Grid>
            {props.complianceProcessData.checkNote && (
              <Grid item xs={12} className="align-self-end">
                <div className="container-details-row-labels" style={{display:'block'}}>
                    {"Note: "}
                  <span className="container-details-row-value">
                  {props.complianceProcessData.checkNote}
                  </span>
                </div>
              </Grid>
            )}

            {props.complianceProcessData.digitalIdentity &&
              props.complianceProcessData.digitalIdentity.importStatus ===
                "Imported" && (
                <Grid item xs={12} className="align-self-end">
                  <div className="container-details-row-labels">
                    {t("check_details.check_header.digital_identity")}:
                    <label className="container-details-row-value">
                      {" "}
                      {props.complianceProcessData.digitalIdentity.providerName}
                    </label>
                  </div>
                </Grid>
              )}
            {canDisplayYotiSessionAndStatus && (
              <>
                <Grid item xs={12} className="align-self-end">
                  <div className="container-details-row-labels">
                    {t("check_details.check_header.status")}:
                    <label className="container-details-row-value">
                      {" "}
                      {props.complianceProcessData.digitalIdentity.importStatus}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} className="align-self-end">
                  <div className="container-details-row-labels">
                    {t("check_details.check_header.session_id")}:
                    <label className="container-details-row-value">
                      {" "}
                      {props.complianceProcessData.digitalIdentity.sessionId}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} className="align-self-end">
                  <div className="container-details-row-labels">
                    {t("check_details.check_header.is_completed")}:
                    <label className="container-details-row-value">
                      {" "}
                      {props.complianceProcessData.digitalIdentity.isComplete
                        ? "Yes"
                        : "No"}
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} className="align-self-end">
                  <div className="container-details-row-labels">
                    {t("check_details.check_header.valid_to")}:
                    <label className="container-details-row-value">
                      {" "}
                      {moment(
                        props.complianceProcessData.digitalIdentity.validTo
                      ).format("YYYY-MM-DD")}
                    </label>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {props.complianceProcessData &&
          props.complianceProcessData.dbsDetails &&
          (props.complianceProcessData.dbsDetails.resultsInfo ||
            props.complianceProcessData.dbsDetails.counterSignDetails ||
            props.complianceProcessData.dbsDetails.requestInfo) && (
            <div className="title">
              {t("check_details.result_details.title")}
            </div>
          )}
        {props.complianceProcessData &&
          props.complianceProcessData!.dbsDetails &&
          props.complianceProcessData!.dbsDetails.resultsInfo && (
            <div className="container-content">
              <DBSResultDetails
                complianceProcessData={props.complianceProcessData!}
              />
            </div>
          )}
        {props.checkHeader?.dbsRequest && (
          <DBSRequestDetails
            complianceProcessData={props.checkHeader?.dbsRequest!}
          />
        )}
        {showRightToWorkCheckStatus && (
          <RTWResultDetails
            complianceProcessData={props.complianceProcessData!}
          />
        )}
        {showIdentityCheckStatus && (
          <IDCheckResultDetails
            complianceProcessData={props.complianceProcessData!}
          />
        )}
      </div>
    </div>
  );
}
