import * as React from "react";
import "./index.scss";

export interface ButtonProps {
  buttonText?: string;
  onClick: (event?: any) => void;
  disabled: boolean;
  type: "secondary" | "primary" | string;
  aditionalClass?: string;
  style?: React.CSSProperties;
  ariaLabel?: string;
}

const GenericButton: React.FC<ButtonProps> = (props) => {
  let { buttonText, disabled, type, aditionalClass } = props;

  let className = "primary-button";
  if (disabled) {
    className = "primary-button primary-disabled";
  }

  if (type === "secondary") {
    className = "secondary-button";
    if (disabled) {
      className = "secondary-button secondary-disabled";
    }
  }

  return (
    <button
      disabled={disabled}
      onClick={props.onClick}
      style={props.style}
      aria-label={(props.ariaLabel ? props.ariaLabel : props.buttonText)}
      className={`button-parent ${className} ${aditionalClass ? aditionalClass : ""}`}
    >
      {buttonText}
    </button>
  );
};

export default GenericButton;