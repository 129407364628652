import { useTranslation } from "react-i18next";
import { Action } from 'react-tiny-fab';
import CheckIcon from '@material-ui/icons/Check';
import StopIcon from '@material-ui/icons/Stop'
import IComplianceProcess from "../../interfaces/IComplianceProcess";
import "../CheckDetails/index.scss";
import { CheckHasClientActions } from "../../Utils/referenceDataService";

interface IRTWActionsProps {
    complianceProcessData: IComplianceProcess;
    handleRightToWorkFlagOnCheck: (hasRightToWork: boolean) => void;
}

export default function RTWActions(props: IRTWActionsProps) {
    const { t } = useTranslation();
    const hasClientActions: boolean = CheckHasClientActions(props.complianceProcessData) || false;

    return (
        <>
            { hasClientActions &&
            <>
            <Action
              text={t("check_summary.has_right_to_work")}
              onClick={() => props.handleRightToWorkFlagOnCheck(true)}>
              <div className="actn-btn">
                <div className="span-txt">
                  <span>{t("check_summary.has_right_to_work")}</span>
                </div>
                <div className="svg-el">
                  <CheckIcon />
                </div>
              </div>
            </Action>
            <Action
                text={t("check_summary.doesnt_have_right_to_work")}
                onClick={() => props.handleRightToWorkFlagOnCheck(false)}>
                <div className="actn-btn">
                <div className="span-txt">
                    <span>{t("check_summary.doesnt_have_right_to_work")}</span>
                </div>
                <div className="svg-el">
                    <StopIcon />
                </div>
                </div>
            </Action>
            </>
            }
        </>
    )
}