import moment from "moment";
import GenericDataShow from "../../../Components/GenericDataShow";
import InfoTypes from "../../../interfaces/InfoTypes";
import "./index.scss";
import { useTranslation } from "react-i18next"
import { GetNationalityDescription } from "../../../Utils/referenceDataService";

interface PreviousNationalityProps {
    prevNationalityData: InfoTypes.PreviousNationalities;
    pNationalityIdx: number;
}
  
export default function PreviousNationality (props: PreviousNationalityProps) {
    const { t } = useTranslation();
    
   
    return (
      <>
        <div id={`persona-info-prevNationality-div-${props.pNationalityIdx}`} className="personal-info-body-container-details empty-row">
          <div className="personal-info-body-container-details-row">
            <div className="oneHalfWidth">
              <GenericDataShow
                label={t("prev_nationality.prev_nationality")}
                value={(props.prevNationalityData.nationality && props.prevNationalityData.nationality !== "") ? GetNationalityDescription(props.prevNationalityData.nationality!) : ""}
              />
            </div>
            <div className="oneHalfWidth">
              
            </div>
          </div>
          <div className="personal-info-body-container-details-row">
            <div className="personal-info-body-container-details-row">
              <div className="oneHalfWidth">
                <GenericDataShow
                  label={t("common.date_from_generic")}
                  value={props.prevNationalityData.from ? moment(props.prevNationalityData.from).format("DD/MM/YYYY") : ""}
                />
              </div>
              <div className="oneHalfWidth">
                <GenericDataShow
                  label={t("common.date_to_generic")}
                  value={props.prevNationalityData.to ? moment(props.prevNationalityData.to).format("DD/MM/YYYY") : ""}
                />
              </div>
            </div>
          </div>
          <div className="personal-info-body-container-details-row empty-row">
            <div>
            </div>
          </div>
        </div>
      </>
    )
}