export enum ProcessStatus {
    Created = "Created",
    WaitingForWorker = "WaitingForWorker",
    WaitingForClient = "WaitingForClient",
    WaitingForCounterSigning = "WaitingForCounterSigning",
    SubmittedToThirdParty = "SubmittedToThirdParty",
    ResultPositive = "ResultPositive",
    ResultNegative = "ResultNegative",
    Cancelled = "Cancelled",
    Complete = "Complete"
}

export enum ComplianceTypes {
    DBS = "DBS",
    LADO = "LADO",
    CSBL = "CSBL",
    OccupationalHealth = "OccupationalHealth",
    EmploymentHistoryAndReferences = "EmploymentHistoryAndReferences",
    QTS = "QTS",
    ProfessionalRegistry = "ProfessionalRegistry",
    OverseasPolice = "OverseasPolice",
    DVLA = "DVLA",
    IdentityAndRWAudit = "IdentityAndRWAudit",
    IdentityCheck = "IdentityCheck",
    AcademicReferences = "AcademicReferences",
    AcademicReferencesSpecial = "AcademicReferencesSpecial",
}

export enum ProviderStatus
{
    Submitted = "Submitted",
    SubmittedForSponsorship = "SubmittedForSponsorship",
    ApplicationReceived = "ApplicationReceived",
    PoliceNationalComputerSearch = "PoliceNationalComputerSearch",
    AssembleCertificate = "AssembleCertificate",
    CertificateIssuedDispatched = "CertificateIssuedDispatched",
    LocalPoliceSearch = "LocalPoliceSearch",
    NoRecordFoundforDetailsProvided = "NoRecordFoundforDetailsProvided",
    Complete = "Complete",
    Error = "Error"
}

export enum DbsCheckType
{
    Standard = "Standard",
    Enhanced = "Enhanced",
    Basic = "Basic",
}

export enum DbsCheckTypeNonBasic
{
    Standard = "Standard",
    Enhanced = "Enhanced",
}

export enum WorkForceType
{
    Other = "Other",
    ChildWorkforce = "ChildWorkforce",
    AdultWorkforce = "AdultWorkforce",
    ChildAndAdultWorkforce = "ChildAndAdultWorkforce"
}

export enum DisclosureResult
{
    None = "None",
    PleaseWaitToViewApplicantCertificate = "PleaseWaitToViewApplicantCertificate",
    CertificateContainsNoInformation = "CertificateContainsNoInformation",
    Other = "Other",
}

export enum ConfirmActions {
    None = "None",
    Cancel = "Cancel",
    SubmitToDBS = "SubmitToDBS",
    SubmitToCountersign = "SubmitToCountersign",
    Complete = "Complete",
    HasRightToWork = "HasRightToWork",
    DoesntHaveRightToWork = "DoesntHaveRightToWork",
    ConfirmIdentity = "ConfirmIdentity",
    RejectIdentity = "RejectIdentity",
    ResetYotiSession = "ResetYotiSession"
}

export enum DocumentType {
    NotSet,
    UkPassport,
    NonUkPassport,
    UkPhotoDriverLicence,
    NonUkPhotoDriverLicence,
    UkPaperDriverLicence,
    IdCard,
    UtilityBill,
    SettledStatusShareCode,
    Document
}

export enum ProcessSource {
    CORE = "CORE"
} 

export enum EmailAddressSource {
    TalentClouds = "talentclouds.co.uk"
} 