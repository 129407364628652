import moment from "moment";
import { useEffect, useState } from "react";
import GenericDataShow from "../../../Components/GenericDataShow";
import InfoTypes from "../../../interfaces/InfoTypes";
import { GetCountryDescription } from "../../../Utils/referenceDataService";
import "./index.scss";

interface PreviousAddessProps {
    prevAddressData: InfoTypes.PreviousAddress;
    pAddressIdx: number;
}
  
interface PreviousAddressState {
    mappedPrevAddressData: InfoTypes.PreviousAddress;
}

export default function ReviousAddress (props: PreviousAddessProps, state: PreviousAddressState) {

    const [prevAddressValue, setPrevAddressValue] = useState<PreviousAddressState>({
        mappedPrevAddressData: props.prevAddressData
    });

    useEffect(() => {
        setPrevAddressValue({
            mappedPrevAddressData: props.prevAddressData
        });
    }, [props.prevAddressData])

    return (
        <>
        <div id={`persona-info-prevAddress-div-${props.pAddressIdx}`} className="address-history-body-container-details empty-row">
            <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Postal Code"}
                        value={prevAddressValue.mappedPrevAddressData.postCode}
                    />
                </div>
                <div className="oneHalfWidth">
                </div>
            </div>
            <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Building"}
                        value={prevAddressValue.mappedPrevAddressData.building}
                    />
                </div>
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Street"}
                        value={prevAddressValue.mappedPrevAddressData.street}
                    />
                </div>
            </div>
            <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Town"}
                        value={prevAddressValue.mappedPrevAddressData.town}
                    />
                </div>
                <div className="oneHalfWidth">
                    
                </div>
            </div>
            <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"County"}
                        value={prevAddressValue.mappedPrevAddressData.county}
                    />
                </div>
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Country"}
                        value={(prevAddressValue.mappedPrevAddressData.country && prevAddressValue.mappedPrevAddressData.country !== "") ? GetCountryDescription(prevAddressValue.mappedPrevAddressData.country!) : ""}
                    />
                </div>
            </div>
            <div className="address-history-body-container-details-row">
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Resident From"}
                        value={prevAddressValue.mappedPrevAddressData.residentFrom ? moment(prevAddressValue.mappedPrevAddressData.residentFrom).format("DD/MM/YYYY") : ""}
                    />
                </div>
                <div className="oneHalfWidth">
                    <GenericDataShow
                        label={"Resident To"}
                        value={prevAddressValue.mappedPrevAddressData.residentTo ? moment(prevAddressValue.mappedPrevAddressData.residentTo).format("DD/MM/YYYY") : ""}
                    />
                </div>
            </div>
            <div className="address-history-body-container-details-row empty-row">
                <div>
                </div>
            </div>
        </div>
        </>
    )
}