import "./index.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      {" "}
      <div className="footer-wrapper">
        <div className="content-text" aria-label={"Copyright " + year + " Talent Clouds. All Rights Reserved."}>
            © {year}&nbsp;<b>Talent Clouds</b>. All Rights Reserved.
        </div>
        <div className="footer-right">
            <a className="content-link pad-right" href="/" target="_blank" aria-label="Privacy Policy">Privacy Policy</a>
            <a className="content-link" href="/" target="_blank" aria-label="Cookie Policy">Cookie Policy</a>
        </div>
      </div>
    </>
  );
};

export default Footer;
