import IComplianceProcess from "../../interfaces/IComplianceProcess";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import moment from "moment";
import constants from "../../Constants/constants";
import IAuditData from "../../interfaces/IAuditData";

interface DBSResultDetailsProps {
  complianceProcessData: IComplianceProcess;
}

const DisplayPropertyValueArray: React.FC<{ 
  data: IAuditData[]; 
  targetEvent: string, 
  title: string
  notSet: string 
}> = ({ 
  data = [], 
  title,
  targetEvent,
  notSet
}) => {
  return (
    <>
      {data
        .filter((obj) => obj.event === targetEvent)
        .map((obj, index) => (
          <Grid item xs={12} className="align-self-end" key={index}>
            <div className="container-details-row-labels">
              {title}:
              <label className="container-details-row-value">
                {moment(obj.requestedOn).format('YYYY/MM/DD') || notSet}
              </label>
            </div>
          </Grid>
        ))}
    </>
  );
};


export default function DBSResultDetails(props: DBSResultDetailsProps) {
  const { t } = useTranslation();
  const dbsInfo = props.complianceProcessData.dbsDetails?.resultsInfo || {};

  const dob = moment(props.complianceProcessData.processData.personal?.dateOfBirth, 'YYYY/MM/DD');
  const day = dob.format('D');
  const month = dob.format('M');
  const year = dob.format('YYYY');

  const auditData: IAuditData[] | undefined = props.complianceProcessData.audit;

  return (
    <div id="request-results">
      {dbsInfo && (
        <Grid container className="header-info" direction="column" alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={12} className="align-self-end">
            <div className="container-details-row-labels">
              {t("check_details.result_details.e_number")}:
              <label className="container-details-row-value">
                {dbsInfo.dbsReference ? (
                  <a
                    href={`${constants.DBS_TRACKING_SERVICE_URL}?applicationNo=${dbsInfo.dbsReference}&dateOfBirthDay=${day}&dateOfBirthMonth=${month}&dateOfBirthYear=${year}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dbsInfo.dbsReference}
                  </a>
                ) : (
                  t("common.not_set")
                )}
              </label>
            </div>
          </Grid>
          {dbsInfo.externalReference && (
            <Grid item xs={12} className="align-self-end">
              <div className="container-details-row-labels">
                {t("check_details.result_details.external_reference")}:
                <label className="container-details-row-value">{dbsInfo.externalReference}</label>
              </div>
            </Grid>
          )}
          <Grid item xs={12} className="align-self-end">
            <div className="container-details-row-labels">
              {t("check_details.result_details.disclosure_number")}:
              <label className="container-details-row-value">
                {dbsInfo.disclosureNumber || t("common.not_set")}
              </label>
            </div>
          </Grid>
          <Grid item xs={12} className="align-self-end">
            <div className="container-details-row-labels">
              {t("check_details.result_details.disclosure_result")}:
              <label className="container-details-row-value">
                {dbsInfo.disclosureResult || t("common.not_set")}
              </label>
            </div>
          </Grid>
          <Grid item xs={12} className="align-self-end">
            <div className="container-details-row-labels">
              {t("check_details.result_details.disclosure_status")}:
              <label className="container-details-row-value">
                {dbsInfo.currentStatus || t("common.not_set")}
              </label>
            </div>
          </Grid>
          {(props.complianceProcessData.audit && auditData) &&
            <DisplayPropertyValueArray 
              data={auditData} 
              targetEvent="DbsResultReceived"
              title={t("check_details.result_details.dbs_issue_date")}
              notSet={t("common.not_set")} 
            />
          }
        </Grid>
      )}
    </div>
  );
}
