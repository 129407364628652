import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import LocalStorageService from "../Utils/localStorageService";

const LogoutButton = () => {
  const { logout } = useAuth0();

 useEffect(() => {
      LocalStorageService.removeAllData();
    logout({ returnTo: window.location.origin });
  })

  return <></>;
};

export default LogoutButton;