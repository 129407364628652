import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CookieStorageService from "../Utils/cookieStorageService";
import Constants from "../Constants/constants";

interface State<T> {
  loading: boolean;
  data?: T | null;
  error?: Error | unknown | null;
}

export function useApi<T>(
  url: string,
  options: any = {} //TODO
): State<T> {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState<State<T>>({
    error: null,
    loading: true,
    data: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const { scope, ...fetchOptions } = options;
        const accessToken = await getAccessTokenSilently();
        
        if(accessToken) {
          CookieStorageService.setCookie(Constants.ACCESS_TOKEN, accessToken);
        }
        const res = await fetch( process.env.REACT_APP_APIGATEWAY + url, {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            // Add the Authorization headers to the existing headers
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        setState({
          ...state,
          data: await res.json(),
          error: null,
          loading: false,
        });
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false,
        });
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
}