import "./index.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next"

export default function Dashboard() {
    
    const { t } = useTranslation();
    const { user } = useAuth0();
    
    return (
      < div id="dashboard-wrapper" className="dashboard-wrapper">
            <div className="title" aria-label={t("dashboard.greet")}> {t("dashboard.greet")} </div>
            <div className="title" aria-label={user?.name}>{user?.name}</div>
        </div>
    );
  }
