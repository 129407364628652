import Cookies from "js-cookie";
import moment from "moment";

class CookieStorageService {
  static getCookie(key: string): string {
    return Cookies.get(key) ? Cookies.get(key)! : "";
  }

  static setCookie(key: string, data: string, expiresCookie?: Date | number) {
    Cookies.set(key, data, {
      expires: expiresCookie || moment().add(12, "hours").toDate(),
      path: "/",
      secure: true,
      sameSite: "none",
    });
  }

  static removeCookie(key: string) {
    Cookies.remove(key, { path: "/" });
  }

}
export default CookieStorageService;
