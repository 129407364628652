import "./index.scss";
import InfoTypes from "../../../interfaces/InfoTypes";
import { useEffect, useState } from "react";
import IComplianceProcess from "../../../interfaces/IComplianceProcess";
import IOtherInformation from "../../../interfaces/IOtherInformation";
import { useTranslation } from "react-i18next"
import { LoaderModal } from "../../../Components/LoaderModal";


type WorkedAbroadProps = {
  content: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
  enableCountersign?: boolean;
}

export default function WorkedAbroad(props: WorkedAbroadProps) {
    const { t } = useTranslation();
    const [otherInfo, setOtherInfo] = useState<IOtherInformation>();
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
      setLoading(true);
      if (props.complianceProcessData && props.complianceProcessData.processData && props.complianceProcessData.processData.other) {
        setOtherInfo(props.complianceProcessData.processData.other);
      }
      
      setLoading(false);
    },[props.complianceProcessData]);
  
    return (
    <div id="worked-abroad" className="worked-abroad editor-component container-max-height">
      <div className="worked-abroad-body">
        <div className="worked-abroad-body-container">
          <div className="worked-abroad-body-container-details-row">
          {(props.content) && (props.content.content) && props.content.content !== "" ?
            <div className="worked-abroad-body-container-details-row-content">
            { props.content.content.indexOf('</') !== -1
              ? (
                  <div className="editor-component-body" dangerouslySetInnerHTML={{__html: props.content.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                  </div>
                )
              : ""
            }
            </div>
            : ""}
            <div className="worked-abroad-body-container-details-row-content editor-component-body" style={{float: "none"}}>
             {t("worked_abroad.worked_abroad_ques")}
            </div>
            <div className="worked-abroad-body-container-details-row-content editor-component-body check-box-ctrls" style={{float: "none"}}>
            { otherInfo !== undefined ? 
              <div className="value-label">{ (otherInfo.hasLivedOrWorkedAbroadInLast5Years) ? t("common.yes") : t("common.no")}</div>
            : 
              <div className="value-label">{t("common.no_data_provided")}</div>
            }
            </div>
          </div>
        </div>
        <LoaderModal isLoading={loading} />
      </div>
    </div>
  );
}
