import "./index.scss";
import InfoTypes from "../../../interfaces/InfoTypes";
import { useEffect, useState } from "react";
import IComplianceProcess from "../../../interfaces/IComplianceProcess";
import IConvictionsInformation from "../../../interfaces/IConvictionsInformation";
import { useTranslation } from "react-i18next"
import { LoaderModal } from "../../../Components/LoaderModal";

type ConvictionsProps = {
  content: InfoTypes.ContentDefinition;
  complianceProcessData: IComplianceProcess;
}

export default function Convictions(props: ConvictionsProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [convictionsInfo, setConvictionsInfo] = useState<IConvictionsInformation>();
    
    useEffect(() => {
      setLoading(true);
      if (props.complianceProcessData && props.complianceProcessData.processData && props.complianceProcessData.processData.convictions) {
        setConvictionsInfo(props.complianceProcessData.processData.convictions);
      }
      
      setLoading(false);
    },[props.complianceProcessData]);
  
  return (
    <div id="convictions" className="convictions editor-component container-max-height">
      <div className="convictions-body">
        <div className="convictions-body-container">
          <div className="convictions-body-container-details-row">
          {(props.content) && (props.content.content) && props.content.content !== "" ?
            <div className="convictions-body-container-details-row-content">
            { props.content.content.indexOf('</') !== -1
              ? (
                  <div className="editor-component-body" dangerouslySetInnerHTML={{__html: props.content.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                  </div>
                )
              : ""
            }
            </div>
            : ""}
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.unspent_convictions_ques")}
            </div>
            <div className="worked-abroad-body-container-details-row-content check-box-ctrls editor-component-body">
            { convictionsInfo !== undefined ? 
              <div className="value-label">{ (convictionsInfo.hasUnspentConvictions) ? t("common.yes") : t("common.no")}</div>
            : 
              <div className="value-label">{t("common.no_data_provided")}</div>
            }
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.declare_unprotected_warning")}
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.complete_declaration_req")}
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("convictions.overall_convictions_ques")}
            </div>
            <div className="worked-abroad-body-container-details-row-content check-box-ctrls editor-component-body">
            { convictionsInfo !== undefined ? 
              <div className="value-label">{ (convictionsInfo.unProtectedConvictions) ? t("common.yes") : t("common.no")}</div>
            : 
              <div className="value-label">{t("common.no_data_provided")}</div>
            }
            </div>
            <div className="worked-abroad-body-container-details-row-content convictns-desc editor-component-body">
              { (convictionsInfo !== undefined && convictionsInfo.unProtectedConvictions) ? 
              <>
              <div className="desc-text-area">
                <div className="value-label">{convictionsInfo.unprotectedConvictionsDescription}</div>
              </div>
              <div className="desc-text-area">
              </div>
              </> : ""
              }
            </div>
            <div className="convictions-body-container-details-row-content editor-component-body">
              {t("common.more_info_reference")} <a href="https://www.gov.uk/government/publications/dbs-filtering-guidance/dbs-filtering-guide"> {t("convictions.dbs_filtering_guidelines")}</a> {t("convictions.agreement_statement")}
            </div>
            <div className="worked-abroad-body-container-details-row-content">
            </div>
          </div>
        </div>
        <LoaderModal isLoading={loading} />
      </div>
    </div>
  );
}
