import "./index.scss";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import 'react-tiny-fab/dist/styles.css';
import IComplianceProcess from "../../interfaces/IComplianceProcess";

interface IDCheckResultDetailsProps {
    complianceProcessData: IComplianceProcess;
}

export default function IDCheckResultDetails(props: IDCheckResultDetailsProps) {
    const { t } = useTranslation();

    return (
        <div>
            <div id="check-header">
                <div className="request-details-content">
                    <Grid container className="header-info" direction="column" alignItems="flex-start" justifyContent="flex-start">
                        <Grid item xs={12} className="align-self-end">
                            <div className="container-details-row-labels">{t("check_details.request_details.identity_verified")}:
                                <label className="container-details-row-value"> {(props.complianceProcessData.processData.other?.hasIdentityChecked)  ? t("common.yes") : t("common.no")}</label>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}
