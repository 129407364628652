import GenericModal from "../../Components/GenericModal";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GenericTextField from "../../Components/GenericTextField";
import GenericButton from "../../Components/GenericButton";
import { InputLabel } from "@material-ui/core";
import Constants from "../../Constants/constants";
import secureFetch from "../../Services/secureFetch";

interface RequestChangeModalProps {
    toggleModal: () => void;
    isOpen: boolean;
    complianceProcessId: string;
    onCloseModal: (isSuccess: boolean) => void;
}

const RequestChangeModal = (props: RequestChangeModalProps) => {
    const [reasonForChange, setReasonForChange] = useState('')
    const [callSubmit, setCallSubmit] = useState(false);
    const { t } = useTranslation();
    
    const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        setReasonForChange(value)
    }

    useEffect(() => {
        async function SendSubmitRequest() {
          if (callSubmit) {
            await SubmitCommentsToWorker();
          }
        }
        SendSubmitRequest();
    }, [callSubmit])
    
    const SubmitCommentsToWorker = () => {
        secureFetch(Constants.API_URLS.DBS_SUFFIX + props.complianceProcessId + Constants.API_URLS.POST_SUBMIT_CHANGE_REQUEST, {
            method: "POST", 
            body: {
                changesRequested: reasonForChange
            }
        }).then((res) => {
            if (res.ok) {
                props.onCloseModal(true);
            } else {
                props.onCloseModal(false);
            }
        }).catch((error) => {
            props.onCloseModal(false);
        })
        props.toggleModal();
    }

    const handleSubmitToWorker = () => {
        setCallSubmit(true);
    }

    return (
        <GenericModal
            heading={t("check_summary.request_change_title")}
            onCloseModal={props.toggleModal}
            open={props.isOpen}
            closeOnBackDrop={true}
            modalWidth={"626px"}
            modalHeight={"375px"}
            modalBottom={"30%"}
            modalPadding={"29px 60px 0px 60px"}
            className="request-change-modal"
        >
            <>
            <InputLabel> {t("request_comments.request_change_label")}</InputLabel>
            <GenericTextField
                label={""}
                placeholder={t("request_comments.enter_comments")}
                name={"unprotectedConvictionDesc"}
                className="request-change"
                type="text"
                id={"unprotectedConvictionDesc"}
                variant="outlined"
                multiline={true}
                margin="dense"
                fullWidth={true}
                value={reasonForChange}
                rows={6}
                onChangeValue={(e, val) => handleInputChange(e, val)}
                error={false}
                helperText={""}
            />
            <GenericButton
                onClick={handleSubmitToWorker}
                type="success"
                style={{ backgroundColor: '#93E088', borderColor: '#93E088', marginLeft: "auto",  marginTop: "30px"}}
                disabled={(reasonForChange === "")}
                buttonText={t("request_comments.submit_to_worker")}
            ></GenericButton>
            </>
        </GenericModal>
    )
}

export default RequestChangeModal