import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, CssBaseline } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
  },
  content: {
    flexGrow: 2,
    display: "flex",
    marginTop: "0px", 
  },
  container: {
    flexGrow: 2,
    minWidth: "100%",
    display:"flex",
    minHeight: `calc(100vh - 140px)`,
    marginTop: "0px",
    backgroundColor: "#F0F0F0",
    border: "1px transparent",
    paddingTop: "0px",
  },
  appBarSpacer: theme.mixins.toolbar,
}));

type Props = {
  children: NonNullable<ReactNode>;
};

const OtherLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
        <div className={classes.root}>
            <CssBaseline />
            <main className="no-layout-Screen">
            <Container maxWidth="lg" className={classes.container}>
                {children}
            </Container>
            </main>
        </div>
  );
};

export default OtherLayout;
