import "./index.scss";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import 'react-tiny-fab/dist/styles.css';
import IComplianceProcess from "../../interfaces/IComplianceProcess";

interface DBSRequestDetailsProps {
    complianceProcessData: IComplianceProcess;
}

export default function DBSRequestDetails(props: DBSRequestDetailsProps) {
    const { t } = useTranslation();

    const checkRequestData = props.complianceProcessData.dbsDetails ? (props.complianceProcessData.dbsDetails!.counterSignDetails ? props.complianceProcessData.dbsDetails!.counterSignDetails : 
                                        props.complianceProcessData.dbsDetails!.requestInfo) : null;
    return (
        <div>
            {props.complianceProcessData.dbsDetails && (props.complianceProcessData.dbsDetails!.counterSignDetails || 
                                                        props.complianceProcessData.dbsDetails!.requestInfo) ?
                <div id="check-header">
                    <div className="request-details-content">
                        <Grid container className="header-info" direction="column" alignItems="flex-start" justifyContent="flex-start">
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.level_of_check_required")}:
                                    <label className="container-details-row-value"> {checkRequestData!.dbsCheckType}</label>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.workforce_type")}:
                                    <label className="container-details-row-value"> {checkRequestData!.workforceType}</label>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.q1")}
                                    <label className="container-details-row-value"> {checkRequestData!.covid19FastTrackRequired ?  t("common.yes") : t("common.no")}</label>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.q2")}
                                    <label className="container-details-row-value"> {checkRequestData!.isVolunteer ? t("common.yes") : t("common.no")}</label>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.q3")}
                                    <label className="container-details-row-value"> {checkRequestData!.worksFromHome ? t("common.yes") : t("common.no")}</label>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.q4")}
                                    <label className="container-details-row-value"> {checkRequestData!.childrensBarredList ? t("common.yes") : t("common.no")}</label>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="align-self-end">
                                <div className="container-details-row-labels">{t("check_details.request_details.q5")}
                                    <label className="container-details-row-value"> {checkRequestData!.adultsBarredList ? t("common.yes") : t("common.no")}</label>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div> : <div> </div>
            }
        </div>
    )
}
