import { PropsWithChildren } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { GenericLoader } from "../Components/GenericLoader";

const ProtectedRoute = ({ component, ...args }: PropsWithChildren<any>) => (
  <Route
    exact
    component={withAuthenticationRequired(
      component, {
      onRedirecting: () => <GenericLoader />,
    })}
    {...args}
    
  />
);

export default ProtectedRoute;
